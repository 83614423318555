import blueArrow from '../../../images/button-arrow.png';

export default theme => ({
    accordion: {
        position: 'relative',
        marginBottom: 36,
        boxShadow: 'none',
        [theme.breakpoints.up('md')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '87.5%',
        }, 
        '& .MuiPaper-elevation1': { boxShadow: 'none !important' },
        '& > div': {
            boxShadow: 'none',
            '& .MuiAccordionSummary-root': {
                padding: 0,
                backgroundColor: theme.palette.primary.main+'1A',
                marginBottom: 16,
                minHeight: '0px !important',
                borderRadius: 0,
                transition: 'background-color .3s ease-in-out',
                '& .MuiAccordionSummary-expandIcon': {
                    height: 20,
                    width: 20,
                    backgroundImage: 'url(' + blueArrow + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    marginRight: 20,
                    transition: '.3s ease-in-out transform',
                    '& svg': {
                        display: 'none',
                    }
                },
                '&.Mui-expanded': {
                    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                },
                '& .MuiAccordionSummary-content': {
                    marginTop: 0,
                    marginBottom: 0,
                    '& h1, & h2, & h3, & h4, & h5': {
                        padding: '24px 32px',
                        linHeight: 1.2,
                        fontFamily: theme.bodyFont,
                        color: theme.palette.primary.main,
                        fontWeight: 400,
                        fontSize: 18,
                        transition: 'color .3s ease-in-out',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 24,
                        }
                    }
                }
            },
            '& .MuiAccordionDetails-root': {
                display: 'block',
                padding: 32,
                [theme.breakpoints.up('md')]: {
                    padding: '32px 80px',
                },
                '& h1, & h2, & h3, & h4, & h5': {
                    marginBottom: 18,
                    color: theme.palette.primary.main,
                }
            }
        }
    },
    accordionTheme: {
        ...theme.accordionTheme,
    }
})