import icon from "../../../images/search-white.png";
import more from "../../../images/banner-more.png";
import liquid from "../../../images/banner-liquid.png";
import splut from "../../../images/landing-banner-split.png";

export default (theme) => ({
  landingBannerVideo: {
    position: "relative",
    width: "100vw",
    marginLeft: "50%",
    marginBottom: 150,
    transform: "translateX(-50%)",
    padding: "100px 12px",
    [theme.breakpoints.up("md")]: {
      padding: "0 12px 50px",
    },
    "&::before": {
      position: "absolute",
      bottom: 0,
      top: 0,
      left: "50%",
      width: "100vw",
      backgroundColor: theme.palette.secondary.main,
      zIndex: -1,
      display: "block",
      content: '""',
      transform: "translateX(-50%)",
    },
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: -1,
      left: 0,
      right: 0,
      height: 36,
      backgroundPosition: "center top",
      backgroundImage: "url(" + splut + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.up("md")]: {
        height: 140,
      },
    },
    "& .video-wrapper": {
      paddingBottom: "56% !important",
      width: "100vw",
      marginLeft: "50%",
      transform: "translateX(-50%)",
      position: "relative",
      overflow: "hidden",
      "& iframe": {
        position: "absolute",
        top: -5,
        left: "-1vw",
        right: "-1vw",
        bottom: -5,
        width: "102vw",
        height: "calc(100% + 10px)",
      },
    },
    "& .container": {
      maxWidth: 1270,
      padding: "0 12px",
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: 0,
        bottom: 75,
        width: "100%",
        zIndex: 1,
      },
      "& .content": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
          maxWidth: "50%",
          padding: "0 12px",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 43.75%",
          maxWidth: "43.75%",
          padding: "0 12px",
        },
        "& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5": {
          fontSize: 38,
          letterSpacing: "-0.016em",
          lineHeight: 1.15,
          margin: "50px 0",
          fontWeight: "800",
          fontFamily: theme.headerFont,
          color: "white",
          [theme.breakpoints.up("md")]: {
            lineHeight: 1.125,
            fontSize: 64,
            margin: "0 0 24px",
          },
        },
        "& form": {
          position: "relative",
          "& label": {
            position: "absolute",
            top: 12,
            left: 31,
            opacity: 0.5,
            fontWeight: "bold",
            textTransfrom: "uppercase",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            fontSize: 10,
            letterSpacing: "0.152em",
            lineHeight: 1.2,
            [theme.breakpoints.up("md")]: {
              top: 26,
              left: 41,
            },
          },
          '& input[type="text"]': {
            border: "1px solid white",
            borderRadius: 50,
            padding: "28px 60px 10px 30px",
            display: "block",
            margin: 0,
            width: "100%",
            lineHeight: 1,
            fontSize: 12,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            fontFamily: theme.bodyFont,
            letterSpacing: "0.032em",
            outline: "none",
            [theme.breakpoints.up("md")]: {
              padding: "42px 100px 26px 40px",
              fontSize: 16,
              maxWidth: 530,
            },
            "& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder": {
              color: theme.palette.primary.main,
              opacity: 1,
            },
          },
          '& input[type="submit"]': {
            position: "absolute",
            outline: "none",
            top: 11,
            right: 11,
            width: 32,
            height: 32,
            border: "none",
            backgroundColor: "transparent",
            borderRadius: "50%",
            fontSize: 0,
            backgroundPosition: "center",
            backgroundImage: "url(" + icon + ")",
            backgroundSize: "cover",
            [theme.breakpoints.up("md")]: {
              width: 49,
              height: 49,
              top: 21,
              right: 21,
            },
          },
        },
      },
    },
  },
  landingBannerVideoTheme: {
    ...theme.landingBannerTheme,
  },
});
