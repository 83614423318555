export default theme => ({
    factStack: {
        position: 'relative',
        '&::before': {
            content: '"Did you know?"',
            fontSize: 10,
            letterSpacing: '0.152em',
            lineHeight: 1.6,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 20,
            padding: '12px 24px',
            display: 'block',
            position: 'absolute',
            left: 32,
            top: -20,
            zIndex: 2,
            textTransform: 'uppercase',
            [theme.breakpoints.up('md')]: {
                left: 55,
            }
        },
    },
    factStackTheme: {
        ...theme.factStackTheme,
    }
})