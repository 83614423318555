import pageTitle from '../../../images/page-title.png';
import currentPage from '../../../images/pagination-dot.png';

export default theme => ({
    blogPagePosts: {
        position: 'relative',
        paddingBottom: 50,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
        },
        marginBottom: 36,
        '& .page-title': {
            position: 'relative',
            height: 140,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 36,
            [theme.breakpoints.up('md')]: {
                height: 284,
            },
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
                color: theme.palette.primary.contrastText,
                marginBottom: 0,
                position: 'relative',
            },
            '&::before': {
                display: 'block',
                width: '100vw',
                position: 'absolute',
                zIndex: 0,
                content: '""',
                top: 0,
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundImage: 'url(' + pageTitle + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            },
        },
        '& .blog-list': {
            position: 'relative',
            zIndex: '1',
            '& .featured-item': {
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'block',
                marginBottom: 30,
                textDecoration: 'none',
                overflow: 'hidden',
                marginTop: -58,
                width: '87.5%',
                marginLeft: 'auto',
                marginRight: 'auto',
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.up('md')]: {
                    marginTop: -90,
                },
                '& .gatsby-image-wrapper': {
                    '& > div': {
                        paddingBottom: '265px !important',
                        [theme.breakpoints.up('md')]: {
                            paddingBottom: '485px !important',
                        },
                    }
                },
                '& .con-wrap': {
                    display: 'block',
                    padding: 30,
                    backgroundColor: theme.palette.primary.main,
                    [theme.breakpoints.up('md')]: {
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '25px 36px',
                        backgroundColor: theme.palette.primary.main+'5E',
                        backdropFilter: 'blur(5px)'
                    },
                    '& .date': {
                        fontSize: 8,
                        lineHeight: 2,
                        letterSpacing: '0.152em',
                        marginBottom: 12,
                        display: 'block',
                        textTransform: 'uppercase',
                    },
                    '& .title': {
                        display: 'block',
                        marginBottom: 15,
                        fontSize: 20,
                        lineHeight: 1.4,
                        letterSpacing: '0.008em',
                        fontWeight: '700',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 32,
                            lineHeight: 1.5,
                        },
                    },
                    '& .excerpt': {
                        opacity: .57,
                        fontSize: 16,
                        lineHeight: 1.5,
                        letterSpacing: '0.016em',
                    }
                }
            },
            '& .list-item': {
                marginBottom: 36,
                [theme.breakpoints.up('md')]: {
                    width: '87.5%',
                    margin: '0 auto 36px',
                },
                '& .gatsby-image-wrapper': {
                    overflow: 'hidden',
                    '& > div': {
                        paddingBottom: '78% !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        float: 'left',
                    }
                },
                '& .con-wrap': {
                    padding: '24px 35px',
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        float: 'left',
                        padding: '70px 80px',
                    },
                    '& .date': {
                        color: theme.palette.primary.main,
                        fontSize: 10,
                        letterSpacing: '0.152em',
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        margin: 0,
                        marginBottom: 10,
                    },
                    '& h2': {
                        color: theme.palette.primary.main,
                        fontFamily: theme.bodyFont,
                        fontSize: 24,
                        lineHeight: 1.4,
                        marginBottom: 16,
                        marginTop: 0,
                        fontWeight: '500',
                    },
                    '& p': {
                        fontSize: 14,
                        lineHeight: 1.5,
                        marginBottom: 32,
                        letterSpacing: '0.032em',
                        [theme.breakpoints.up('md')]: {
                            fontSize: 16,
                        },
                    },
                    '& .cta': {
                        fontSize: 16,
                        lineHeight: 1.5,
                        display: 'inline-block',
                        marginTop: 16,
                        padding: '15px 50px',
                        borderRadius: 6,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontWeight: '500',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                        transition: 'background-color .3s ease-in-out',
                        '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: 17,
                        }
                    }
                },
                '&::after': {
                    clear: 'both',
                    display: 'table',
                    content: '""',
                }
            }
        },
        '& ul.MuiPagination-ul': {
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main + '1A',
            '& li': {
                '& button': {
                    fontSize: 18,
                    fontWeight: 500,
                    margin: '0 10px',
                    borderRadius: 0,
                    transition: 'opacity .3s ease-in-out',
                    backgroundColor: 'transparent !important',
                    '&:hover, &:focus': {
                        opacity: '.8',
                    },
                    '& span': {
                        display: 'none',
                    },
                    '&.Mui-selected': {
                        backgroundImage: 'url('+currentPage+')',
                        backgroundPosition: 'center',
                        color: theme.palette.primary.contrastText,
                        width: 58,
                        height: 80,
                        paddingTop: 20,
                        marginTop: -25,
                        marginBottom: -5,
                    }
                }
            },
            [theme.breakpoints.up('md')]: {
                width: '87.5%',
                margin: '0 auto 36px',
            }
        }
    },
    blogPagePostsTheme: {
        ...theme.blogPagePostsTheme,
    }
})