export default (theme) => ({
  root: {
    "&.img-align-left": {
      margin: "0 0 24px 24px",
      float: "left",
    },
    "&.img-align-right": {
      margin: "0 24px 24px 0",
      float: "right",
    },
    "&.img-align-center": {
      margin: "0 auto 24px",
    },
    "&.left-top > img, &.left-top > picture > img": {
      objectPosition: "left top !important",
    },
    "&.left-center > img, &.left-center > picture > img": {
      objectPosition: "left center !important",
    },
    "&.left-bottom > img, &.left-bottom > picture > img": {
      objectPosition: "left bottom !important",
    },
    "&.center-top > img, &.center-top > picture > img": {
      objectPosition: "center top !important",
    },
    "&.center-bottom > img, &.center-bottom > picture > img": {
      objectPosition: "center bottom !important",
    },
    "&.right-top > img, &.right-top > picture > img": {
      objectPosition: "right top !important",
    },
    "&.right-center > img, &.right-center > picture > img": {
      objectPosition: "right center !important",
    },
    "&.right-bottom > img, &.right-bottom > picture > img": {
      objectPosition: "right bottom !important",
    },
    "&.page-title-overlap": {
      width: "87.5%",
      margin: "-72px auto 36px",
    },
    "&.full-width": {
      width: "100vw !important",
      maxWidth: "100vw !important",
      marginLeft: "50%",
      transform: "translateX(-50%)",
    },
  },
  imageCaption: {
    fontSize: 10,
    textTransform: "uppercase",
    letterSpacing: ".152em",
    lineHeight: "1.6",
    fontWeight: "500",
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
    padding: "15px 0",
    margin: 0,
    // color: theme.palette.secondary.contrastText,
    // padding: '56px 24px',
    // marginBottom: 0,
    // [theme.breakpoints.up('md')]: {
    //     letterSpacing: '0.007em',
    //     fontSize: 34,
    //     lineHeight: '1.25',
    //     padding: '75px 60px',
    // }
  },
  imageCaptionTheme: {
    ...theme.imageCaptionTheme,
  },
});
