export default theme => ({
    imageLinkSlider: {
        position: 'relative',
        padding: '90px 0 190px;',
        marginBottom: 36,
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '50%',
            top: 0,
            bottom: 255,
            width: '100vw',
            transform: 'translateX(-50vw)',
            backgroundColor: theme.palette.secondary.main,
            [theme.breakpoints.up('md')]: {
                bottom: 380,
            }
        },
        '& .slick-slider': {
            position: 'relative',
            zIndex: '1',
            width: 'calc(100% + 34px)',
            margin: '0 -17px',
            height: '336px !important',
            '& .slick-slide': {
                padding: '12px 12px 24px',
            },
            '& .slick-dots': {
                top: '-35px',
                bottom: 'auto',
                '& li button:before': {
                    color: theme.palette.secondary.contrastText,
                    fontSize: 16,
                }
            }
        },
        '& h2': {
            color: theme.palette.secondary.contrastText,
            position: 'relative',
            zIndex: '1',
            marginBottom: 60,
        },
        '& .bg-image': {
            display: 'block',
            position: 'absolute',
            left: '50%',
            bottom: 0,
            height: 255,
            width: '100vw',
            transform: 'translateX(-50vw)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            [theme.breakpoints.up('md')]: {
                height: 380,
            }
        }
    },
    imageLinkSliderTheme: {
        ...theme.imageLinkSliderTheme,
    }
})