import pageTitle from '../../../images/page-title.png';

import mapPin from '../../../images/footer-icon-map.png';
import phoneIcon from '../../../images/footer-icon-phone.png';
import emailIcon from '../../../images/footer-icon-email.png';

import facebook from '../../../images/footer-icon-facebook.png';
import linkedin from '../../../images/footer-icon-linkedin.png';


export default theme => ({
    contactBanner: {
        position: 'relative',
        marginBottom: 36,
        '& .page-title': {
            position: 'relative',
            height: 140,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                height: 284,
            },
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
                color: theme.palette.primary.contrastText,
                marginBottom: 0,
                position: 'relative',
            },
            '&::before': {
                display: 'block',
                width: '100vw',
                position: 'absolute',
                zIndex: 0,
                content: '""',
                top: 0,
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundImage: 'url(' + pageTitle + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            },
        },
        '& .map-block': {
            position: 'relative',
            '& .desktop-image': {
                display: 'none !important',
                [theme.breakpoints.up('md')]: {
                    display: 'block !important',
                },
            },
            '& .mobile-image': {
                display: 'block !important',
            },
            [theme.breakpoints.up('md')]: {
                marginTop: -65,
                '& .mobile-image': {
                    display: 'none !important',
                }
            },
            '& > .gatsby-image-wrapper': {
                height: '475px !important',
                marginLeft: '50%',
                transform: 'translateX(-50%)',
                maxWidth: '100vw',
                width: 'calc(100% + 50px) !important',
                zIndex: '1',
                '& img': {
                    objectPosition: 'center bottom !important',
                },
                [theme.breakpoints.up('md')]: {
                    height: '560px !important',
                    width: '100% !important',
                    '& img': {
                        objectPosition: 'center right !important',
                    },
                },
            },
            '& .contact-wrap': {
                position: 'absolute',
                zIndex: '1',
                borderRadius: 8,
                backgroundColor: theme.palette.primary.main,
                padding: 32,
                top: 32,
                [theme.breakpoints.up('md')]: {
                    padding: '65px 80px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '6.25%',
                    maxWidth: '40%',
                },
                '& > p, & .phone-link, & .email-link': {
                    display: 'block',
                    paddingLeft: 35,
                    marginTop: 0,
                    fontSize: 16,
                    lineHeight: '24px',
                    letterSpacing: '0.032em',
                    marginBottom: 22,
                    backgroundPosition: 'top 4px left',
                    backgroundRepeat: 'no-repeat',
                    color: theme.palette.primary.contrastText,
                    textDecoration: 'none',
                },
                '& > p': {
                    fontSize: 14,
                    backgroundImage: 'url(' + mapPin + ')',
                    maxWidth: 170
                },
                '& .phone-link': {
                    backgroundImage: 'url(' + phoneIcon + ')',
                    transition: 'opacity .3s ease-in-out',
                    '&:hover, &:focus': {
                        color: theme.palette.primary.contrastText,
                        opacity: 0.8,
                    }
                },
                '& .email-link': {
                    backgroundImage: 'url(' + emailIcon + ')',
                    transition: 'opacity .3s ease-in-out',
                    '&:hover, &:focus': {
                        color: theme.palette.primary.contrastText,
                        opacity: 0.8,
                    }
                },
                '& a.social-link': {
                    backgroundPosition: 'center right',
                    height: 26,
                    width: 212,
                    lineHeight: '26px',
                    fontSize: 14,
                    letterSpacing: '0.032em',
                    color: theme.palette.primary.contrastText,
                    display: 'block',
                    marginBottom: 12,
                    position: 'relative',
                    backgroundRepeat: 'no-repeat',
                    transition: 'opacity .3s ease-in-out',
                    '&:hover, &:focus': {
                        color: theme.palette.primary.contrastText,
                        opacity: 0.8,
                    },
                    '& svg': {
                        position: 'absolute',
                        width: 26,
                        height: 26,
                        top: 0,
                        right: 0,
                    }
                },
            },
        }
    },
    contactBannerTheme: {
        ...theme.contactBannerTheme,
    }
})