export default theme => ({
    introGallery: {
        position: 'relative',
        // margin: '125px 0',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
          // margin: '100px 0 135px',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            marginBottom: 20,
            lineHeight: 1.25,
            [theme.breakpoints.up('md')]: {
                marginTop: 50,
                marginBottom: 36,
            }
        },
        '& p' : {
            marginBottom: 25,
            lineHeight: 1.75,
            letterSpacing: '0.032em',
        },
        '& .con-wrap': {
            [theme.breakpoints.up('md')]: {
                top: 0,
                left: '8.333333%',
                zIndex: 1,
                minHeight: 380,
            }
        },
        '& .image-wrap': {
            padding: '12px 0',
            maxWidth: 'calc(100% + 10px)',
            flexBasis: 'calc(100% + 10px)',
            marginLeft: -5,
            marginRight: -5,
            [theme.breakpoints.up('md')]: {
                padding: 12,
                maxWidth: '100%',
                flexWidth: '100%',
                marginLeft: 0,
                marginRight: 0,
                marginTop: '-30%',
            },
            '& ul.MuiGridList-root': {
                display: 'grid',
                gridGap: 20,
                gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)',
                gridTemplateRows: 'auto auto',
                margin: '0 !important',
                overflow: 'hidden',
                [theme.breakpoints.up('md')]: {
                    gridTemplateColumns: 'calc(28% - 13px) calc(39% - 13px) calc(33% - 13px)',
                    gridTemplateRows: 'auto auto auto',
                },
                '& li': {
                    width: '100% !important',
                    padding: '0 !important',
                    display: 'none',
                    '&:nth-of-type(1)': {
                        gridColumnStart: 1,
                        gridColumnEnd: 3,
                        display: 'block',
                        [theme.breakpoints.up('md')]: {
                            gridColumnStart: 2,
                            gridColumnEnd: 3,
                            gridRowStart: 2,
                            gridRowEnd: 3,
                        },
                        '& img, & .gatsby-image-wrapper': {
                            '& > div': {
                                paddingBottom: '60% !important',
                            }
                        },
                    },
                    '&:nth-of-type(2)': {
                        display: 'block',
                        '& img, & .gatsby-image-wrapper': {
                            '& > div': {
                                paddingBottom: '190% !important',
                            }
                        },
                        [theme.breakpoints.up('md')]: {
                            gridColumnStart: 3,
                            gridColumnEnd: 4,
                            gridRowStart: 1,
                            gridRowEnd: 3,
                        },
                    },
                    '&:nth-of-type(3)': {
                        display: 'block',
                        '& img, & .gatsby-image-wrapper': {
                            '& > div': {
                                paddingBottom: '166.666% !important',
                            }
                        },
                        [theme.breakpoints.up('md')]: {
                            gridColumnStart: 1,
                            gridColumnEnd: 2,
                            gridRowStart: 2,
                            gridRowEnd: 4,
                        },
                    },
                }
            }
        }
    },
    introGalleryTheme: {
        ...theme.introGalleryTheme,
    }
})