export default theme => ({
    socialShare: {
        position: 'relative',
        paddingBottom: 15,
        // width: 136,
        borderRadius: 25,
        backgroundColor: 'white',
        transition: 'box-shadow .3s ease-in-out',
        zIndex: 10,
        '&[open]': {
            boxShadow: '0 24px 26px rgba(0,0,0,0.28)',
            '& .buttons': {
                height: 'auto',
                opacity: 1,
            }
        },
        '& button': {
            color: theme.palette.primary.main + '4D',
            border: 'none',
            outline: 'none',
            backgroundColor: 'transparent',
            fontSize: 18,
            fontWeight: 'bold',
            lineHeight: '23px',
            fontFamily: theme.bodyFont,
            padding: '12px 24px',
            textAlign: 'center',
            letterSpacing: '0.008em',
            whiteSpace: 'no-wrap',
            '& svg': {
                display: 'inline-block',
                verticalAlign: 'sub',
                marginLeft: 10,
            },
        },
        '& .buttons': {
            padding: '0 15px',
            opacity: 0,
            height: 0,
            overflow: 'hidden',
            transition: 'opacity .3s ease-in-out',
            '& a': {
                display: 'block',
                margin: '0 0 5px',
                fontSize: 16,
                textTransform: 'uppercase',
                fontFamily: theme.bodyFont,
                fontWeight: 'bold',
                color: 'white',
                textAlign: 'center',
                width: '100%',
                borderRadius: 23,
                padding: '12px 0',
                textDecoration: 'none',
                transition: 'opacity .3s ease-in-out',
                '&:hover, &:focus': {
                    opacity: 0.8,
                },
                '& svg': {
                    display: 'inline-block',
                    verticalAlign: 'sub',
                    marginRight: 2,
                },
                '&.email': { backgroundColor: theme.palette.primary.main},
                '&.facebook': { backgroundColor: "#385C8E"},
                '&.whatsapp': { backgroundColor: "#30BA45"},
                '&.twitter': { backgroundColor: "#03A9F4"},
            }
        }
    },
});