export default theme => ({
    iconRow: {
        position: 'relative',
        marginBottom: 36,
        width: '100vw',
        maxWidth: '100vw',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
        }
    },
    iconRowTheme: {
        ...theme.iconListTheme,
    }
})