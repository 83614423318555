import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbIconListInnerBlock = (props) => {
    const classes = useStyles();

    return <li className={`icon-list ${props.attributes.className ? props.attributes.className : ''} ${classes.iconListInner} ${classes.iconListInnerTheme}`}>
        {ReactHtmlParser(props.originalContent)}
        {props.attributes.text}
    </li>
};

export const CgbIconListInnerBlockFragment = graphql`
  fragment CgbIconListInnerBlock on WpBlock {
    ... on WpCgbIconListInnerBlock {
        name
        originalContent
        attributes {
            className
            text
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbIconListInnerBlockPreview = `
    ... on CgbIconListInnerBlock {
        name
        originalContent
        attributes {
            className
            text
        }
    }
`;