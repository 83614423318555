import beta from '../../../images/beta-logo.png';
import cronex from '../../../images/cronex-logo.png';
import dudley from '../../../images/dudley-logo.png';
import epson from '../../../images/epson-logo.png';
import resan from '../../../images/resan-logo.png';
import externalLink from '../../../images/external-link.png';

export default theme => ({
    brandLink: {
        position: 'relative',
        display: 'block',
        padding: '120px 20px 58px',
        backgroundColor: theme.palette.primary.main,
        textDecoration: 'none',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 72,
        },
        '& p': {
            color: theme.palette.primary.contrastText,
            marginTop: 0,
            marginBottom: 18,
            lineHeight: 1.5,
            minHeight: 120,
        },
        '& .button': {
            fontWeight: '700',
            lineHeight: 1.25,
            fontSize: 18,
            width: 200,
            color: theme.palette.primary.contrastText,
            border: '1px solid ' + theme.palette.primary.contrastText,
            borderRadius: 25,
            textAlign: 'center',
            padding: 8,
            display: 'block',
            position: 'absolute',
            left: 20,
            bottom: 18,
            [theme.breakpoints.up('md')]: {
                left: 50,
                bottom: 32,
            }
        },
        '&::after': {
            clear: 'both',
            display: 'table',
            content: '""',
        },
        '&.dudley': {
            backgroundImage: 'url('+ dudley + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top 35px left',
            backgroundColor: '#006BDC',
        },
        '&.epson': {
            backgroundImage: 'url('+ epson + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top 35px left',
            backgroundColor: '#00A296',
        },
        '&.beta': {
            backgroundImage: 'url('+ beta + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top 35px left',
            backgroundColor: '#FF6A00',
        },
        '&.cronex': {
            backgroundImage: 'url('+ cronex + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top 35px left',
            backgroundColor: '#DD003B',
            '& .button': {
                float: 'none',
                marginBottom: 60,
                marginTop: 60,
                width: 160,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                    marginTop: 0,
                    width: 200,
                },
                '&::after': {
                    content: '""',
                    width: 18,
                    height: 18,
                    backgroundImage: 'url('+externalLink+')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: 10,
                    display: 'inline-block',
                }
            }
        },
        '&.resan': {
            backgroundImage: 'url('+ resan + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top 35px left',
            backgroundColor: '#103073',
            '& .button': {
                float: 'none',
                marginBottom: 60,
                width: 160,
                marginTop: 60,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 0,
                    marginTop: 0,
                    width: 200,
                },
                '&::after': {
                    content: '""',
                    width: 18,
                    height: 18,
                    backgroundImage: 'url('+externalLink+')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: 10,
                    display: 'inline-block',
                }
            }
        },
    },
    brandLinkTheme: {
        ...theme.brandLinkTheme,
    }
})