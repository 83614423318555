import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { postData } from "./actions";

import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreParagraphBlock } from "../CoreParagraphBlock";

import styles from "./Form.styles";

const useStyles = makeStyles((theme) => styles(theme));

function QuoteBuilder() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [query, setQuery] = useState("");
  const [formLocked, lockForm] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const request = postData({
      forename: name,
      surname,
      email,
      country,
      query: query,
    });

    request.then((res) => {
      if (res.status === 200 && res.data.success === true) {
        lockForm(true);
      }
    });
  }

  return (
    <div className="enquiry-form">
      {formLocked && (
        <p className={classes.success}>
          Thank you. A member of the team will get back to you shortly.
        </p>
      )}
      {!formLocked && <h3>Contact us today</h3>}

      {!formLocked && (
        <p>
          Fill in the contact form below and one of our specialist team will be
          in touch.
        </p>
      )}

      {!formLocked && (
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <fieldset>
            <label for="name">First Name*</label>
            <input
              type="text"
              name="name"
              placeholder="John"
              required
              onChange={(e) => setName(e.target.value)}
            />

            <label for="surname">Last Name*</label>
            <input
              type="text"
              name="surname"
              placeholder="Smith"
              required
              onChange={(e) => setSurname(e.target.value)}
            />

            <label for="email">Email*</label>
            <input
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <label for="country">Country*</label>
            <input
              type="text"
              name="country"
              placeholder="United Kingdom"
              required
              onChange={(e) => setCountry(e.target.value)}
            />

            <label for="query">Query*</label>
            <textarea
              name="query"
              placeholder="Message here..."
              onChange={(e) => setQuery(e.target.value)}
            />

            <input type="submit" value="Submit" id="ContactFormSubmit" />

            <p>*Field is required</p>
          </fieldset>
        </form>
      )}
    </div>
  );
}

export default QuoteBuilder;
