export default theme => ({
    pullquote: {
        position: 'relative',
        marginBottom: 36,
        '& > .gatsby-image-wrapper': {
            height: 550,
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw !important',
            width: 'calc(100% + 50px) !important',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                height: 'auto',
                width: '100vw !important',
            },
        },
        '& .quote-block': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: '1',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& .quote-text': {
                color: 'white',
                fontSize: 32,
                lineHeight: 1.2,
                letterSpacing: '-0.0088em',
                fontFamily: theme.headerFont,
                maxWidth: 800,
                margin: '0 auto 30px',
                [theme.breakpoints.up('md')]: {
                    fontSize: 60,
                    lineHeight: 1.25,
                },
                '& p': {
                    margin: 0,
                },
                '&::before' : {
                    content: '\'"\'',
                    display: 'table',
                    margin: '0 auto',
                    lineHeight: '1',
                    transformOrigin: '50% 0',
                    transform: 'scale(2)',
                }
            },
            '& > p': {
                color: 'white',
                fontFamily: theme.subHeaderFont,
                padding: '14px 30px',
                backgroundColor: '' + theme.palette.secondary.main + 'BF',
                fontSize: 10,
                fontWeight: 600,
                lineHeight: 1.6,
                letterSpacing: '0.152em',
                display: 'table',
                margin: '0 auto',
                textTransform: 'uppercase',
                [theme.breakpoints.up('md')]: {
                    fontSize: 14,
                }
            }
        }
    },
    pullquoteTheme: {
        ...theme.pullquoteTheme,
    }
})