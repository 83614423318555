export default theme => ({
    imageBreakdownInner: {
        position: 'relative',
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            marginBottom: 15,
        },
        '& p' : {
            marginBottom: 34,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
                fontWeight: 500,
                lineHeight: 1.33,
                margin: '42px 0',
            }
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, &  .MuiTypography-h6, & .MuiTypography-body1': {
            color: theme.palette.primary.contrastText,
        },
    },
    imageBreakdownInnerTheme: {
        ...theme.imageBreakdownInnerTheme,
    }
})