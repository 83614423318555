import React from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import { transformLists } from "../../../helpers/";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import { buildBlocks } from "../../../services/builder";

const useStyles = makeStyles((theme) => styles(theme));

export const CoreListBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  );
  // console.log(props);
  const classes = useStyles();

  let content = false;
  if (props.attributes?.values) {
    content = ReactHtmlParser(props.attributes.values, {
      transform: transformLists,
    });
  } else {
    content = blocks.map((block) => {
      return block;
    });
  }

  if (props.attributes.ordered === true) {
    return (
      <ol
        className={` ${
          props.attributes.className ? props.attributes.className : ""
        } ${classes.list}`}
        start={props.attributes.start ? props.attributes.start : 1}
      >
        {content}
      </ol>
    );
  }

  return (
    <ul
      className={` ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.list}`}
    >
      {content}
    </ul>
  );
};

CoreListBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreListBlockFragment = graphql`
  fragment CoreListBlock on WpBlock {
    ... on WpCoreListBlock {
      name
      attributes {
        ... on WpCoreListBlockAttributes {
          className
          ordered
          reversed
          start
          type
          values
        }
      }
    }
  }
`;

export const CoreListBlockPreview = `
  ...on CoreListBlock {
    name
    attributes {
      ...on CoreListBlockAttributes {
        className
        ordered
        reversed
        start
        type
        values
      }
    }
  }
  `;
