export default theme => ({
    bannerMessage: {
        position: 'relative',
        marginBottom: 36,
        '& > .gatsby-image-wrapper': {
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw',
            width: 'calc(100% + 50px) !important',
            zIndex: '1',
            overflow: 'hidden',
            backgroundColor: '#333',
            '& > div': {
                paddingBottom: '550px !important',
            },
            '& .video-wrapper': {
                position: 'absolute',
                top: '50%',
                bottom: 0,
                width: '100%',
                paddingBottom: '0 !important',
                height: '56vw',
                minHeight: 595,
                minWidth: 1070,
                left: '50%',
                transform: 'translate(-50%, -50%)',
                '& iframe': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100% !important',
                    height: '100% !important',
                    minHeight: 595,
                    minWidth: 1070,
                },
            },
            [theme.breakpoints.up('md')]: {
                height: 'auto !important',
                width: '100vw !important',
                '& > div': {
                    paddingBottom: '640px !important',
                }
            },
        },
        '& .content-wrap': {
            position: 'absolute',
            bottom: 90,
            zIndex: '3',
            [theme.breakpoints.up('sm')]: {
                maxWidth: '85%'
            },
            [theme.breakpoints.up('md')]: {
                bottom: 50,
            },
            '& > a': {
                marginTop: 30,
                display: 'table',
            }
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            color: 'white',
            marginBottom: '0',
        },
    },
    bannerMessageTheme: {
        ...theme.videoBannerMessageTheme,
    }
})