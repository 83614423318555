import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbCardRowBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`card-row ${props.attributes.className ? props.attributes.className : ''} ${classes.cardRow} ${classes.cardRowTheme} ${props.attributes.hasBlackCards}  ${props.attributes.hasTopImages} ${props.attributes.hasBackground} ${props.attributes.isFullWidth}`}>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={4} >
                {blocks[0]}
            </Grid>
            <Grid item xs={12} md={4} >
                {blocks[1]}
            </Grid>
            <Grid item xs={12} md={4} >
                {blocks[2]}
            </Grid>
        </Grid>
    </div>
};

export const CgbCardRowBlockFragment = graphql`
  fragment CgbCardRowBlock on WpBlock {
    ... on WpCgbCardRowBlock {
        name
        attributes {
            className
            hasBlackCards
            hasTopImages
            isFullWidth
            hasBackground
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCardRowBlockPreview = `
    ... on CgbCardRowBlock {
        name
        attributes {
            className
            hasBlackCards
            hasTopImages
            isFullWidth
            hasBackground
        }
    }
`;