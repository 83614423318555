import React from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import Link from "../../Link";
import styles from './styles';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

const useStyles = makeStyles(theme => styles(theme));

export const CgbContactBannerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();
    // if( ! props.attributes.mediaUrl) return null;

    let image = null;

    // Use the gatsby image if available
    if(props.attributes.localFile){
        if(props.attributes.localFile.childImageSharp){
            image = <Img
                className="desktop-image"
                fluid={props.attributes.localFile.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                className="desktop-image"
                src={props.attributes.localFile.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper desktop-image">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }
    let mobileImage = null;

    // Use the gatsby image if available
    if(props.attributes.localFileMobile){
        if(props.attributes.localFileMobile.childImageSharp){
            mobileImage = <Img
                className="mobile-image"
                fixed={props.attributes.localFileMobile.childImageSharp.fixed}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            mobileImage = <img
                className="mobile-image"
                src={props.attributes.localFileMobile.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        mobileImage = <div className="gatsby-image-wrapper mobile-image">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrlMobile}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }
    const data = useStaticQuery(graphql`
      query ContactInfo {
        wp {
          locationData {
            LocationData {
                address1
                address2
                email
                name
                postcode
                town
                telephone
                region
                facebookHandle
                instagramHandle
                twitterHandle
                linkedinUrl
                youtubeUrl
            }
          }
        }
      }
    `);

    return <div
        className={`contact-banner ${props.attributes.className ? props.attributes.className : ''} ${classes.contactBanner} ${classes.contactBannerTheme}`}
    >
        <div className="page-title">
            {blocks.map((block) => {
                return block;
            })}
        </div>
        <div className="map-block">
            {image}
            {mobileImage}
            <div className="contact-wrap">
                <p>
                    {data.wp.locationData.LocationData.address1 !== null &&
                        <span>{data.wp.locationData.LocationData.address1}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.address2 !== null &&
                        <span>{data.wp.locationData.LocationData.address2}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.town !== null &&
                        <span>{data.wp.locationData.LocationData.town}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.region !== null &&
                        <span>{data.wp.locationData.LocationData.region}, <br/></span>
                    }
                    {data.wp.locationData.LocationData.postcode !== null &&
                        <span>{data.wp.locationData.LocationData.postcode} </span>
                    }
                </p>
                <Link to={"tel:" + data.wp.locationData.LocationData.telephone} className="phone-link">{data.wp.locationData.LocationData.telephone}</Link>
                <Link to={"mailto:" + data.wp.locationData.LocationData.email} className="email-link">{data.wp.locationData.LocationData.email}</Link>
                {data.wp.locationData.LocationData.facebookHandle !== null &&
                <Link className="social-link" to={data.wp.locationData.LocationData.facebookHandle} >Connect on Facebook <FacebookIcon /></Link>
                }
                {data.wp.locationData.LocationData.twitterHandle !== null &&
                <Link className="social-link" to={"https://www.twitter.com/" + data.wp.locationData.LocationData.twitterHandle} >Follow on Twitter <TwitterIcon/></Link>
                }
                {data.wp.locationData.LocationData.instagramHandle !== null &&
                <Link className="social-link" to={data.wp.locationData.LocationData.instagramHandle} >Follow on Instagram <InstagramIcon/></Link>
                }
                {data.wp.locationData.LocationData.linkedinUrl !== null &&
                <Link className="social-link" to={data.wp.locationData.LocationData.linkedinUrl} >Connect on Linkedin <LinkedInIcon /></Link>
                }
                {data.wp.locationData.LocationData.youtubeUrl !== null &&
                <Link className="social-link" to={data.wp.locationData.LocationData.youtubeUrl} >Watch on YouTube <YouTubeIcon /></Link>
                }
            </div>
        </div>
    </div>
};

CgbContactBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbContactBannerBlockFragment = graphql`
    fragment CgbContactBannerBlock on WpBlock {
        ... on WpCgbContactBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbContactBannerBlockAttributes {
                    className
                    mediaUrl
                    mediaUrlMobile
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1660) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    localFileMobile {
                        publicURL
                        childImageSharp {
                            fixed(quality: 80, width: 960) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbContactBannerBlockPreview = `
    ... on CgbContactBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbContactBannerBlockAttributes {
                className
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`;