import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import {buildBlocks} from "../../../services/builder";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'block',
        position: 'relative',
        marginBottom: 36,
    },
}));

export const CoreButtonsBlock = (props) => {
    const classes = useStyles();
  
    const blocks = buildBlocks(props.innerBlocks, props.attributes, props.pageContext);
    return <div className={`button-group ${props.attributes.className ? props.attributes.className : ''} ${classes.buttons}`}>
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

CoreButtonsBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreButtonsBlockFragment = graphql`
  fragment CoreButtonsBlock on WpBlock {
    ... on WpCoreButtonsBlock {
      name
      attributes {
        __typename
       ... on WpCoreButtonsBlockAttributes {
          className
          align
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreButtonsBlockPreview = `
... on CoreButtonsBlock {
    name
    attributes {
      __typename
     ... on CoreButtonsBlockAttributes {
        className
        align
      }
    }
  }
`;