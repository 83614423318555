export default theme => ({
    twoColInner: {
        position: 'relative',
        marginBottom: 36,
        '& > *': {
            marginBottom: 36,
        }
    },
    twoColInnerTheme: {
        ...theme.twoColInnerTheme,
    }
})