export default theme => ({
    iconListIntro: {
        position: 'relative',
        // padding: '24px 0',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            // padding: '110px 0 94px',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            marginBottom: 20,
            letterSpacing: '0.06em',
        },
        '& p': {
            lineHeight: '1.8',
            marginBottom: 0,
        },
        '& .MuiGrid-root': {
            justifyContent: 'space-between',
        }
    },
    iconListIntroTheme: {
        ...theme.iconListIntroTheme,
    }
})