export default theme => ({
    teamGrid: {
        position: 'relative',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            width: '87.5%',
            margin: '0 auto 36px',
        },
        '& .team-members': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '0 -12px',
            width: 'calc(100% + 24px)',
            maxWidth: 'calc(100% + 214px)',
            alignItems: 'stretch',
            [theme.breakpoints.up('md')]: {
                margin: '0 -40px',
                width: 'calc(100% + 80px)',
                maxWidth: 'calc(100% + 80px)',
            },
            '& .team-grid-inner': {
                flex: '1 0 100%',
                maxWidth: '100%',
                padding: '0 12px 32px',
                [theme.breakpoints.up('sm')]: {
                    flex: '0 50%',
                    maxWidth: '50%',
                    padding: '0 12px 32px',
                },
                [theme.breakpoints.up('md')]: {
                    flex: '0 25%',
                    maxWidth: '25%',
                    padding: '0 40px 32px',
                },
            }
        }
    },
    teamGridTheme: {
        ...theme.teamGridTheme,
    }
})