import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const AcfNbsIframeBlock = (props) => {
  const classes = useStyles();

  return (
    <div
      className={`nbs-iframe ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.iframe}`}
    >
      <iframe
        title="NBS Source website integration"
        src="https://websiteintegration.source.thenbs.com/products/cc44e8cd-1c0a-0166-7fa2-425bbfc814ac?backgroundColour=ffffff&generalTextColour=031a35&borderColour=dbe0de&linkColour=19365b&defaultButton=action-blue&fontFamily=Verdana&tileTitleSize=16&productTitleSize=24&descriptionSize=14&borderWidth=1&borderRadius=0&productListing=all&size=responsive&showImages=true&showTags=true&showDescription=true&showSourceLink=true&showSpecHelp=true&showProductVerification=true"
      ></iframe>
    </div>
  );
};

AcfNbsIframeBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const AcfNbsIframeBlockFragment = graphql`
  fragment AcfNbsIframeBlock on WpBlock {
    ... on WpAcfNbsIframeBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpAcfNbsIframeBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfNbsIframeBlockPreview = `
    ... on AcfNbsIframeBlock {
        name
        originalContent
        attributes {
            __typename
            ... on AcfNbsIframeBlockAttributes {
                className
            }
        }
    }
`;
