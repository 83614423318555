import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from "@material-ui/core/Grid/Grid";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';
import {CoreHeadingBlock} from "../CoreHeadingBlock";

const useStyles = makeStyles(theme => styles(theme));

export const CgbFeaturedIconsBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`featured-icons ${props.attributes.className ? props.attributes.className : ''} ${classes.featuredIcons} ${classes.featuredIconsTheme}`}>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={4} lg={5} >
                <CoreHeadingBlock 
                    attributes={{
                        align: "",
                        anchor: "",
                        className: "",
                        content: ReactHtmlParser(props.attributes.featuredIconsTitle),
                        level: 2,
                        textColor: "",
                        __typename: "WpCoreHeadingBlockAttributes",
                    }}
                    innerBlocks={[]} 
                />
            </Grid>
            <Grid item xs={12} md={8} lg={7} >
                <Grid container spacing={3} direction="row">
                    {blocks.map((block) => {
                        return <Grid item xs={12} sm={6} md={4} >{block}</Grid>;
                    })}
                </Grid>
            </Grid>
        </Grid>
    </div>
};

export const CgbFeaturedIconsBlockFragment = graphql`
  fragment CgbFeaturedIconsBlock on WpBlock {
    ... on WpCgbFeaturedIconsBlock {
        name
        attributes {
            className
            featuredIconsTitle
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFeaturedIconsBlockPreview = `
    ... on CgbFeaturedIconsBlock {
        name
        attributes {
            className
            featuredIconsTitle
        }
    }
`;