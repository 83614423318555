export default theme => ({
    cardRow: {
        position: 'relative',
        marginBottom: 36,
        '&.has-top-images': {
            '& .card': {
                display: 'flex',
                flexDirection: 'column-reverse',
            }
        },
        '&.has-black-cards': {
            '& .card': {
                backgroundColor: theme.palette.common.black,
                '& .con-wrap': {
                    '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                        color: theme.palette.common.white,
                    }
                }
            }
        }
    },
    cardRowTheme: {
        ...theme.cardRowTheme,
    }
})