import React from "react";
import { AcfBrewGalleryBlockPreview } from "./queries";
import Img from "gatsby-image";

export const AcfBrewGalleryBlock = ({ block }) => {
  const numberOfColumns = block.blockBrewGallery.numberOfColumns;

  const images = block.blockBrewGallery.brewGallery;

  return (
    <figure
      class={`wp-block-gallery columns-${numberOfColumns} is-cropped ${block.attributes.className}`}
    >
      <ul class="blocks-gallery-grid">
        {images.map((image) => (
          <li class="blocks-gallery-item">
            <figure>
              {image.localFile ? (
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.alt ? image.alt : ""}
                  className={`img-align-${image.align} ${
                    image.className ? image.className : ""
                  }`}
                  width={image.width ? image.width : ""}
                  height={image.height ? image.height : ""}
                />
              ) : (
                <div className="gatsby-image-wrapper">
                  <img
                    src={image.mediaItemUrl}
                    style={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                  />
                </div>
              )}
            </figure>
          </li>
        ))}
        {/* <div className={``}>
      <figure className={``}>
        <ul className="">
          {images.map((image) => (
            <li className={``}>
              <PreviewImage
                alt={image.id}
                className="blocks-gallery-item"
                fallbackUrl
                image={image.localFile}
                fixedPadding={undefined}
              />
            </li>
          ))}
        </ul>
      </figure>
    </div> */}
      </ul>
    </figure>
  );
};

export { AcfBrewGalleryBlockPreview };
