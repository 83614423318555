export default (theme) => ({
  file: {
    display: "block",
    position: "relative",
    marginBottom: 36,
    "& button": {
      fontFamily: theme.headerFont,
      padding: "16px 30px",
      boxShadow: "0 8px 10px rgba(0,0,0,0.08)",
      fontSize: 18,
      lineHeight: 1.25,
      fontWeight: 800,
      letterSpacing: "0.008em",
      textAlign: "center",
      borderRadius: 26,
      textTransform: "none",
      margin: "0 !important",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
      "& button": {
        fontFamily: theme.headerFont,
        padding: "3px 30px",
        boxShadow: "0 8px 10px rgba(0,0,0,0.08)",
        fontSize: 18,
        lineHeight: 2.6,
        fontWeight: 800,
        letterSpacing: "0.008em",
        textAlign: "center",
        borderRadius: 26,
        textTransform: "none",
        margin: "0 !important",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
  },
});
