import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbImageSliderBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    // centerMode: true,
    // lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <div
      className={`image-slider ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.imageSlider} ${classes.imageSliderTheme}`}
    >
      <Slider {...settings}>
        {blocks[0].props.block.blockBrewGallery.brewGallery.map((image) => {
          // if(image.fullUrlSharp){
          //     if(image.fullUrlSharp.childImageSharp){
          //         return <div className="image-slide" key={image.id}>
          //             <Img
          //                 fluid={image.fullUrlSharp.childImageSharp.fluid}
          //                 alt={image.alt ? image.alt : ''}
          //             />
          //         </div>
          //     } else {
          //         // Gifs do not have a childImageSharp
          //         return <div className="image-slide" key={image.id}>
          //             <img
          //                 src={image.fullUrlSharp.publicURL}
          //                 alt={image.alt ? image.alt : ''}
          //             />
          //         </div>
          //     }
          // } else {
          return (
            <div className="image-slide" key={image.id}>
              <div className="gatsby-image-wrapper">
                <div style={{ paddingBottom: "50%" }} />
                <img
                  src={image.mediaItemUrl}
                  alt={image.altText ? image.altText : ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                />
              </div>
            </div>
          );
          // }
        })}
      </Slider>
    </div>
  );
};

export const CgbImageSliderBlockFragment = graphql`
  fragment CgbImageSliderBlock on WpBlock {
    ... on WpCgbImageSliderBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageSliderBlockPreview = `
    ... on CgbImageSliderBlock {
        name
        attributes {
            className
        }
    }
`;
