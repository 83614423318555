export default theme => ({
    fileList: {
        position: 'relative',
        marginBottom: 36,
        backgroundColor: theme.palette.secondary.main,
        width: 'calc(100vw)',
        marginLeft: '50%',
        transform: 'translateX(-50vw)',
        padding: '0 12px',
        paddingBottom: '44px',
        paddingTop: '44px',
        '& h2': {
            color: theme.palette.secondary.contrastText,
            padding: '44px 0 44px',
            marginBottom: 0,
        }
    },
    fileListTheme: {
        ...theme.fileListTheme,
    }
})