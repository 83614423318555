import arrowWhite from "../../../images/button-arrow-white.png";
import arrowBlue from "../../../images/button-arrow.png";

export default (theme) => ({
  prodByTag: {
    marginBottom: 36,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& > div": {
      backgroundColor: theme.palette.secondary.main,
      padding: 32,
      marginBottom: 24,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "80px 80px 0",
      },
      "& h3": {
        color: theme.palette.primary.contrastText,
        fontSize: 28,
        lineHeight: 1.15,
        marginTop: 0,
        marginBottom: 24,
        [theme.breakpoints.up("md")]: {
          fontSize: 32,
        },
      },
      "& p": {
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        letterSpacing: "0.032em",
        lineHeight: 1.5,
        marginBottom: 32,
        marginTop: 0,
      },
      "& .links": {
        maxWidth: 180,
        marginBottom: 32,
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.up("md")]: {
          height: 260,
        },
        "& a, & button": {
          width: "fit-content",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          padding: 0,
          textAlign: "left",
          display: "block",
          marginBottom: 16,
          color: theme.palette.primary.contrastText,
          fontFamily: theme.bodyFont,
          fontWeight: 700,
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacign: "0.008em",
          textDecoration: "none",
        },
      },
      "& .image-link": {
        display: "block",
        width: "calc(100% + 64px)",
        maxWidth: "calc(100% + 64px)",
        margin: "0 -32px -32px !important",
        position: "relative",
        [theme.breakpoints.up("md")]: {
          width: "calc(100% + 160px)",
          maxWidth: "calc(100% + 160px)",
          margin: "-300px -80px 0 !important",
        },
        "& .gatsby-image-wrapper": {
          [theme.breakpoints.up("md")]: {
            width: "50%",
            margin: "0 0 0 auto",
          },
          "& > div": {
            paddingBottom: "69% !important",
            [theme.breakpoints.up("md")]: {
              paddingBottom: "410px !important",
            },
          },
          "& img": {
            objectPosition: "top center !important",
          },
        },
        "& span": {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 75,
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.contrastText,
          textDecoration: "none",
          fontSize: 18,
          lineHeight: 1.5,
          padding: "24px 26px",
          [theme.breakpoints.up("md")]: {
            left: 54,
            right: "50%",
            bottom: 50,
          },
        },
        "&::after": {
          content: '""',
          width: 75,
          height: 75,
          display: "block",
          backgroundImage: "url(" + arrowWhite + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          right: 0,
          bottom: 0,
          [theme.breakpoints.up("md")]: {
            right: "auto",
            left: "50%",
            backgroundColor: theme.palette.secondary.main,
            bottom: 50,
          },
        },
      },
    },
    "& > div:nth-of-type(even)": {
      backgroundColor: theme.palette.primary.contrastText,
      "& h3": {
        color: theme.palette.primary.main,
      },
      "& p": {
        color: theme.palette.primary.main,
      },
      "& .links": {
        "& a, & button": {
          color: theme.palette.primary.main,
        },
      },
      "& .image-link": {
        "& span": {
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.primary.main,
        },
        "&::after": {
          [theme.breakpoints.up("md")]: {
            backgroundImage: "url(" + arrowBlue + ")",
            backgroundColor: theme.palette.primary.contrastText,
          },
        },
      },
    },
  },
  prodByTagTheme: {
    ...theme.prodByTagTheme,
  },
});
