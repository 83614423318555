
export default theme => ({
    calloutCta: {
        position: 'relative',
        marginBottom: 36,
        padding: '36px 0',
        width: '100vw',
        left: '50%',
        marginLeft: '-50vw',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            padding: '40px 0',
        },
        '&.contained': {
            [theme.breakpoints.up('md')]: {
                width: 'calc(100vw - 48px)',
                marginLeft: '50%',
                maxWidth: 1345,
                transform: 'translateX(-50%)',
                transformStyle: 'preserve-3d',
                left: 0,
            },
        },
        '& .con-wrap': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
            }
        },
        '& .gatsby-image-wrapper, & img': {
            width: 100,
            margin: '0 auto',
            display: 'block',
            [theme.breakpoints.up('md')]: {
                margin: '0 20px 0 0',
            }
        },
        '& p': {
            fontSize: 24,
            lineHeight: 1.4,
            color: theme.palette.tertiary.contrastText,
            marginBottom: 32,
            fontFamily: theme.headerFont,
            fontWeight: 'bold',
            letterSpacing: '0.04em',
            [theme.breakpoints.up('md')]: {
                fontSize: 32,
                letterSpacing: '0.032em',
                marginBottom: 0,
                flex: '0 0 calc(100% - 120px)',
            },
        },
        '& button': {
            width: '100%',
        },
        '& > div': {
            maxWidth: 1120,
            margin: '0 auto',
            width: 'calc(100% - 24px)',
        }
    },
    calloutCtaTheme: {
        ...theme.calloutCtaTheme,
    }
})