import pageTitle from '../../../images/page-title.png';

export default theme => ({
    pageTitle: {
        position: 'relative',
        marginBottom: 36,
        height: 140,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            height: 284,
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
            color: theme.palette.primary.contrastText,
            marginBottom: 0,
            position: 'relative',
        },
        '&::before': {
            display: 'block',
            width: '100vw',
            position: 'absolute',
            zIndex: 0,
            content: '""',
            top: 0,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundImage: 'url(' + pageTitle + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        },
        '&.no-texture': {
            '&::before': {
                backgroundImage: 'none',
                backgroundColor: theme.palette.primary.main,
            }
        }
    },
    pageTitleTheme: {
        ...theme.pageTitleTheme,
    }
});