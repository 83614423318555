import buttonArrow from "../../../images/button-arrow.png";

export default theme => ({
    form: {
        '& fieldset': {
            border: 'none',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            '& p': {
                color: theme.palette.primary.contrastText,
                letterSpacing: '0.032em',
                lineHeight: 1.5,
            },
            '& label': {
                color: theme.palette.primary.contrastText,
                fontSize: 10,
                lineHeight: 1.6,
                letterSpacing: '0.0152em',
                textTransform: 'uppercase',
                margin: 0,
                fontFamily: theme.bodyFont,
            },
            ' & input, textarea': {
                fontFamily: theme.bodyFont,
                backgroundColor: 'transparent',
                borderRadius: 24,
                fontSize: 16,
                lineHeight: 1.5,
                padding: '12px 30px',
                border: '1px solid ' + theme.palette.primary.contrastText,
                marginBottom: 24,
                color: theme.palette.primary.contrastText,
                outline: 'none',
            },
            '& input[type="submit"]': {
                width: 'auto',
                backgroundColor: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                fontSize: 18,
                display: 'flex',
                margin: '0 0 0 auto',
                padding: '10px 62px 8px 30px',
                backgroundImage: 'url(' + buttonArrow + ')',
                backgroundPosition: 'center right 24px',
                backgroundRepeat: 'no-repeat',
                outline: 'none',
                boxShadow: '0 8px 10px rgb(0 0 0 0.8)',
                cursor: 'pointer',
            }
        }
    },
    success: {
        fontSize: 16,
        color: theme.palette.common.white
    },
    
});