import { graphql } from "gatsby";

export const AcfBrewGalleryBlockFragment = graphql`
  fragment AcfBrewGalleryBlock on WpBlock {
    ... on WpAcfBrewGalleryBlock {
      name
      attributes {
        className
      }
      blockBrewGallery {
        brewGallery {
          id
          uri
          altText
          mediaItemUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        numberOfColumns
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfBrewGalleryBlockPreview = `
  ... on AcfBrewGalleryBlock {
    name
    attributes {
      className
    }
    blockBrewGallery {
      brewGallery {
        id
        uri
        altText
        mediaItemUrl
      }
      numberOfColumns           
    }
  }
`;
