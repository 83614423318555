import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTwoColInnerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`two-col-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.twoColInner} ${classes.twoColInnerTheme}`}>
        {blocks.map((block) => {
            return block;
        })}
    </div>
};

export const CgbTwoColInnerBlockFragment = graphql`
  fragment CgbTwoColInnerBlock on WpBlock {
    ... on WpCgbTwoColInnerBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTwoColInnerBlockPreview = `
    ... on CgbTwoColInnerBlock {
        name
        attributes {
            className
        }
    }
`;