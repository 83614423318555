export default theme => ({
    fileListInnerSmall: {
        position: 'relative',
        boxShadow: '0 7px 6px rgba(0,0,0,0.16)',
        margin: '0 auto 12px',
        maxWidth: 1095,
        padding: 24,
        display: 'block',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 32,
            alignItems: 'center',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            color: theme.palette.secondary.contrastText,
            [theme.breakpoints.up('md')]: {
                padding: 0,
                margin: 0,
            }
        },
        '& .head-col': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                padding: '32px 50px',
            }
        },
        '& .cta-col': {
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                padding: '32px 50px',
                textAlign: 'left',
            }
        }
    },
})