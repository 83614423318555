import icon from "../../../images/search.png";
import more from "../../../images/banner-more.png";
import liquid from "../../../images/banner-liquid.png";
import splut from "../../../images/landing-banner-split.png";

export default theme => ({
    landingBanner: {
        position: 'relative',
        width: '100vw',
        marginLeft: '50%',
        marginBottom: 150,
        transform: 'translateX(-50%)',
        padding: '100px 12px',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        [theme.breakpoints.up('md')]: {
            padding: '165px 12px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 250,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: -1,
            left: 0,
            right: 0,
            height: 36,
            backgroundPosition: 'center top',
            backgroundImage: 'url(' + splut + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            [theme.breakpoints.up('md')]: {
                height: 140,
            }
        },
        '& > .gatsby-image-wrapper': {
            position: 'absolute !important',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            '& img': {
                objectPosition: 'center bottom !important',
            }
        },
        '&::after': {
            content: '""',
            width: 266,
            height: 236,
            position: 'absolute',
            bottom: -150,
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'url('+more+') no-repeat center top, url('+liquid+') top center no-repeat',
            backgroundSize: 'auto, contain',
            [theme.breakpoints.up('md')]:{
                width: 195,
                height: 365,
                top: 'calc(100% - 180px)',
                background: 'url('+more+') no-repeat center top, url('+liquid+') top right no-repeat',
            },
            [theme.breakpoints.up('lg')]:{
                width: 560,
                height: 733,
                backgroundSize: 'auto',
            }
        },
        '& .container': {
            maxWidth: 1270,
            padding: '0 12px',
            margin: '0 auto',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            '& .image': {
                maxWidth: '50%',
                margin: '0 auto',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 41.666%',
                    maxWidth: '41.666%',
                    padding: '0 12px',
                    margin: 0,
                    [theme.breakpoints.up('lg')]: {
                        flex: '0 0 37.5%',
                        maxWidth: '37.5%',
                    },
                },
                '& .video-wrapper': {
                    paddingBottom: '121% !important',
                    width: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                    '& iframe': {
                        position: 'absolute',
                        top: -5,
                        left: -5,
                        right: -5,
                        bottom: -5,
                        width: 'calc(100% + 10px)',
                        height: 'calc(100% + 10px)',
                    },
                    '&::before': {
                        position: 'absolute',
                        zIndex: 2,
                        content: '""',
                        display: 'block',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        border: '5px solid #060e23'
                    }
                },
            },
            '& .content': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 50%',
                    maxWidth: '50%',
                    padding: '0 12px',
                },
                [theme.breakpoints.up('lg')]: {
                    flex: '0 0 43.75%',
                    maxWidth: '43.75%',
                    padding: '0 12px',
                },
                '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5': {
                    fontSize: 38,
                    letterSpacing: '-0.016em',
                    lineHeight: 1.15,
                    margin: '50px 0',
                    fontWeight: '800',
                    fontFamily: theme.headerFont,
                    color: 'white',
                    [theme.breakpoints.up('md')]: {
                        lineHeight: 1.125,
                        fontSize: 64,
                        margin: '0 0 24px',
                    }
                },
                '& form': {
                    position: 'relative',
                    '& label': {
                        position: 'absolute',
                        top: 12,
                        left: 31,
                        opacity: .3,
                        textTransfrom: 'uppercase',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: 8,
                        letterSpacing: '0.152em',
                        lineHeight: 1.2,
                        [theme.breakpoints.up('md')]: {
                            top: 16,
                            left: 41,
                        },
                    },
                    '& input[type="text"]': {
                        border: '1px solid white',
                        borderRadius: 28,
                        padding: '28px 60px 10px 30px',
                        display: 'block',
                        margin: 0,
                        width: '100%',
                        lineHeight: 1,
                        fontSize: 12,
                        color: 'white',
                        backgroundColor: 'transparent',
                        fontFamily: theme.bodyFont,
                        letterSpacing: '0.032em',
                        outline: 'none',
                        [theme.breakpoints.up('md')]: {
                            padding: '32px 90px 16px 40px',
                            fontSize: 16,
                            maxWidth: 530,
                            borderRadius: 36,
                        },
                        '& ::placeholder, & :-ms-input-placeholder, & ::-ms-input-placeholder': {
                            color: 'white',
                            opacity: 1,
                        }
                    },
                    '& input[type="submit"]': {
                        position: 'absolute',
                        outline: 'none',
                        top: 11,
                        right: 11,
                        width: 32,
                        height: 32,
                        border: 'none',
                        backgroundColor: 'transparent',
                        borderRadius: '50%',
                        fontSize: 0,
                        backgroundPosition: 'center',
                        backgroundImage: 'url('+icon+')',
                        backgroundSize: 'cover',
                        [theme.breakpoints.up('md')]: {
                            width: 49,
                            height: 49,
                        },
                    }
                }
            }
        }
    },
    landingBannerTheme: {
        ...theme.landingBannerTheme,
    }
});