import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTeamGridBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`team-grid ${props.attributes.className ? props.attributes.className : ''} ${classes.teamGrid} ${classes.teamGrid}`}>
        <div className="team-members">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const CgbTeamGridBlockFragment = graphql`
  fragment CgbTeamGridBlock on WpBlock {
    ... on WpCgbTeamGridBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTeamGridBlockPreview = `
    ... on CgbTeamGridBlock {
        name
        attributes {
            className
        }
    }
`;