import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTimelineBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`timeline ${props.attributes.className ? props.attributes.className : ''} ${classes.timeline} ${classes.timelineTheme}`}>
        <h2 className="timeline-title">{ReactHtmlParser(props.attributes.timelineTitle)}</h2>
        <p className="timeline-blurb">{ReactHtmlParser(props.attributes.timelineBlurb)}</p>
        <div className="start-dot" />
        {blocks.map((block) => {
            return block;
        })}
        <div className="end-dot" />
    </div>
};

export const CgbTimelineBlockFragment = graphql`
  fragment CgbTimelineBlock on WpBlock {
    ... on WpCgbTimelineBlock {
        name
        attributes {
            className
            timelineTitle
            timelineBlurb
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTimelineBlockPreview = `
    ... on CgbTimelineBlock {
        name
        attributes {
            className
            timelineTitle
            timelineBlurb
        }
    }
`;