import pageTitle from '../../../images/page-title.png';

export default theme => ({
    breakoutIntro: {
        position: 'relative',
        marginBottom: 36,
        // marginBottom: 100,
        '&::after': {
            clear: 'both',
            display: 'table',
            content: '""',
        },
        '& .image-wrapper': {
            position: 'relative',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw',
            width: 'calc(100% + 50px)',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                height: 'auto',
                width: '100vw',
            },
            '& .gatsby-image-wrapper': {
                maxWidth: '100vw !important',
                width: '100% !important'
            },
        },
        '& .content-wrap': {
            position: 'relative',
            backgroundColor: 'white',
            display: 'block',
            marginTop: -80,
            zIndex: 10,
            [theme.breakpoints.up('md')]: {
                marginTop: -300,
            },
            '&::after': {
                content: '""',
                display: 'table',
                clear: 'both',
            }
        },
        '& .header': {
            position: 'relative',
            marginBottom: 36,
            height: 120,
            display: 'flex',
            alignItems: 'center',
            backgroundImage: 'url(' + pageTitle + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            padding: 32,
            fontSize: 48,
            letterSpacing: '-0.016em',
            lineHeight: 1.125,
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.up('md')]: {
                height: 175,
                padding: 80,
                fontSize: 64,
                letterSpacing: '-0.016em',
                lineHeight: 1.33,
                marginBottom: 58,
            },
        },
        '& .bold-con': {
            position: 'relative',
            zIndex: '1',
            width: '87.5%',
            margin: '0 auto',
            [theme.breakpoints.up('md')]: {
                float: 'left',
                width: '43.75%',
                marginLeft: '6.25%',
            },
            '& p': {
                fontWeight: '600',
                marginBottom: 45,
                lineHeight: '2',
                letterSpacing: '0',
                [theme.breakpoints.up('md')]: {
                    fontSize: 24,
                },
            }
        },
        '& .standard-con': {
            position: 'relative',
            zIndex: '1',
            width: '87.5%',
            margin: '0 auto',
            [theme.breakpoints.up('md')]: {
                float: 'left',
                width: '37.5%',
                marginLeft: '6.25%',
            },
            '& p': {
                marginBottom: 45,
                lineHeight: '2',
                letterSpacing: '0.032em',
            }
        },
        '& .gallery': {
            position: 'relative',
            zIndex: '1',
            clear: 'both',
            width: 'calc(100% + 56px)',
            margin: '0 -28px',
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                width: '60%',
                right: '-12px',
                bottom: 'calc(100% + 65px)',
            },
            '& ul': {
                justifyContent: 'center',
                overflow: 'visible',
                '& li': {
                    width: '50% !important',
                    padding: '12px !important',
                    [theme.breakpoints.up('md')]: {
                        width: '33.333% !important',
                        '& > div': {
                            boxShadow: '0 23px 25px rgba(0,0,0,0.4)',
                        }
                    },
                    '& img, & .gatsby-image-wrapper': {
                        '& > div': {
                            paddingBottom: '120% !important',
                        }
                    },
                }
            }
        }
    },
    breakoutIntroTheme: {
        ...theme.breakoutIntroTheme,
    }
})