export default theme => ({
    imageGalleryGrid: {
        position: 'relative',
        marginBottom: 36,
        width: 'calc(100% + 50px)',
        margin: '0 -25px',
        paddingBottom: 24,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
            width: 'calc(100% + 16px)',
            margin: '0 -8px',
        }
    },
    imageGalleryGridTheme: {
        ...theme.imageGalleryGridTheme,
    }
})