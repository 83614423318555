export default theme => ({
    socialSharing: {
        position: 'relative',
        marginBottom: 36,
        '& p.title': {
            fontFamily: theme.headerFont,
            fontSize: 18,
            marginBottom: 10,
            textTransform: 'uppercase',
        },
        '& p.link-list': {
            marginTop: 10,
            '& a': {
                marginRight: 40,
                color: theme.palette.secondary.main,
                '& svg': {
                    width: 40,
                    height: 40,
                },
                '&:hover, &:focus': {
                    color: theme.palette.secondary.dark,
                }
            }
        }
    },
    socialSharingTheme: {
        ...theme.socialSharingTheme,
    }
})