import themePalette from "../../../../.brew-cache/theme.json";
import madeLogo from "../../../images/made-in-logo.png";

export default theme => ({
    para: {
        display: 'block',
        position: 'relative',
        fontSize: 16,
        letterSpacing: '0.024em',
        lineHeight: '2',
        '& a': {
            color: theme.palette.primary.main,
            '&:hover, &:focus': {
                color: theme.palette.primary.dark,
            }
        },
        '&.lead': {
            fontSize: 24,
            lineHeight: '40px',
            letterSpacing: 0,
        },
        '&.caption': {
            marginBottom: '0px !important',
            fontSize: 10,
            lineHeight: 1.6,
            letterSpacing: '0.152em',
        },
        // Forground Colours
        '&[data-text-color="primary"]': { color: themePalette.ThemeOptions.mainColours.primaryColour},
        '&[data-text-color="secondary"]': { color: themePalette.ThemeOptions.mainColours.secondaryColour},
        '&[data-text-color="tertiary"]': { color: themePalette.ThemeOptions.mainColours.tertiaryColour},
        '&[data-text-color="foreground_primary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
        '&[data-text-color="foreground_secondary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
        '&[data-text-color="foreground_tertiary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
        '&[data-text-color="background_primary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
        '&[data-text-color="background_secondary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
        '&[data-text-color="background_tertiary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},
        // Background Colours
        '&:not([data-bg-color="initial"])': {padding: 12},
        '&[data-bg-color="primary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.primaryColour},
        '&[data-bg-color="secondary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.secondaryColour},
        '&[data-bg-color="tertiary"]': { backgroundColor: themePalette.ThemeOptions.mainColours.tertiaryColour},
        '&[data-bg-color="foreground_primary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
        '&[data-bg-color="foreground_secondary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
        '&[data-bg-color="foreground_tertiary"]': { backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
        '&[data-bg-color="background_primary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
        '&[data-bg-color="background_secondary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
        '&[data-bg-color="background_tertiary"]': { backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},
        '&.made-in-britain': {
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1.4,
            letterSpacing: '0.008em',
            paddingLeft: 72,
            backgroundImage: 'url('+madeLogo+')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top left',
            backgroundSize: 56,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
                fontWeight: 500,
                lineHeight: 1.66,
                letterSpacing: 0,
                paddingLeft: 110,
                marginBottom: 80,
                backgroundSize: 80,
            }
        }
    },
    paraTheme: {
        ...theme.paraTheme,
    }
})