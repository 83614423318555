import timelineStart from '../../../images/timeline-start.png';
import timelineEnd from '../../../images/timeline-end.png';

export default theme => ({
    timeline: {
        position: 'relative',
        marginBottom: 36,
        padding: '50px 0 100px',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '100vw',
            left: '50%',
            transform: 'translateX(-50vw)',
            backgroundColor: '#101C32',
        },
        '& .timeline-title': {
            fontSize: 28,
            letterSpacing: '0',
            lineHeight: 1.33,
            color: theme.palette.secondary.contrastText,
            margin: '0 0 24px',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                fontSize: 48,
                margin: '75px 0 36px 12.5%',
                width: '43.75%',
            }
        },
        '& .timeline-blurb, & .timeline-blurb *': {
            fontSize: 14,
            letterSpacing: '0.024em',
            lineHeight: 1.5,
            color: theme.palette.secondary.contrastText,
            margin: '0 0 30px',
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                letterSpacing: '0.032em',
                margin: '0 0 50px 12.5%',
                width: '43.75%',
            }
        },
        '& .start-dot, .end-dot': {
            width: 45,
            height: 55,
            margin: '-5px -20px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.up('md')]: {
                margin: '-5px auto',
            }
        },
        '& .start-dot': { backgroundImage: 'url(' + timelineStart + ')' },
        '& .end-dot': { backgroundImage: 'url(' + timelineEnd + ')' },
        '& *': {
            zIndex: 1,
            position: 'relative'
        },
        '& .timeline-inner:nth-of-type(even)': {
            '& .MuiGrid-container': {
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'row-reverse',
                },
                '& h1, & h2, & h3, & h4, & h5, & h6': {
                    '&::before': {
                        [theme.breakpoints.up('md')]: {
                            left: -35,
                        },
                    }
                },
            }
        }
    },
    timelineTheme: {
        ...theme.timelineTheme,
    }
})