import backArrow from "../../../images/button-arrow.png";

export default (theme) => ({
  blogBanner: {
    position: "relative",
    marginBottom: 36,
    "& .banner": {
      position: "relative",
      paddingTop: 32,
      [theme.breakpoints.up("md")]: {
        paddingTop: 50,
      },
      "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: -1,
        bottom: 50,
        left: "50%",
        width: "100vw",
        transform: "translateX(-50%)",
        backgroundColor: theme.palette.primary.main,
        zIndex: 0,
      },
      "& .date": {
        color: theme.palette.primary.contrastText,
        fontSize: 10,
        lineHeight: 1.6,
        letterSpacing: "0.152em",
        textTransform: "uppercase",
        marginBottom: 10,
        position: "relative",
        fontFamily: theme.bodyFont,
        [theme.breakpoints.up("md")]: {
          width: "75%",
          margin: "0 auto 10px",
        },
      },
      "& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6, & .MuiTypography-body1": {
        color: theme.palette.primary.contrastText,
        marginTop: 0,
        position: "relative",
        marginBottom: 12,
        [theme.breakpoints.up("md")]: {
          width: "75%",
          margin: "0 auto 24px",
        },
      },
      "& p.MuiTypography-body1": {
        marginBottom: 50,
      },
      "& .wp-block-image": {
        position: "relative",
        [theme.breakpoints.up("md")]: {
          width: "75%",
          margin: "0 auto !important",
        },
      },
      "& figure, & img": {
        position: "relative",
      },
    },
    "& .extras": {
      paddingTop: 24,
      position: "relative",
      [theme.breakpoints.up("md")]: {
        paddingTop: 0,
        marginTop: -24,
      },
      "& .go-back": {
        "& button": {
          fontSize: 18,
          padding: 0,
          border: "none",
          backgroundColor: "transparent",
          color: theme.palette.primary.main,
          letterSpacing: "0.008em",
          textTransform: "uppercase",
          fontFamily: theme.bodyFont,
          outline: "none",
          "&::before": {
            content: '""',
            backgroundImage: "url(" + backArrow + ")",
            width: 17,
            height: 20,
            marginRight: 10,
            display: "inline-block",
            verticalAlign: "middle",
            transform: "rotate(180deg)",
          },
        },
      },
      "& .share": {
        position: "absolute",
        top: 10,
        right: 0,
        [theme.breakpoints.up("md")]: {
          top: 0,
        },
      },
    },
  },
  blogBannerTheme: {
    ...theme.blogBannerTheme,
  },
});
