import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Link from '../../Link';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTeamGridInnerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`team-grid-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.teamGridInner} ${classes.teamGridInnerTheme}`}>
        <div className="member-image">
            <div className="reg">{blocks[0]}</div>
            <div className="hover">{blocks[1]}</div>
        </div>
        <div className="con-wrap">
            {blocks[2]}
            <p className="job-title">{props.attributes.jobTitle}</p>
            {blocks[3]}
        </div>
        {props.attributes.email !== '' && props.attributes.email !== '##' &&
            <Link to={"mailto:" + props.attributes.email} className="email">Email me</Link>
        }
    </div>
};

export const CgbTeamGridInnerBlockFragment = graphql`
  fragment CgbTeamGridInnerBlock on WpBlock {
    ... on WpCgbTeamGridInnerBlock {
        name
        attributes {
            className
            jobTitle
            email
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTeamGridInnerBlockPreview = `
    ... on CgbTeamGridInnerBlock {
        name
        attributes {
            className
            jobTitle
            email
        }
    }
`;