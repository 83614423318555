export default theme => ({
    factStackInner: {
        position: 'relative',
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: 8,
        marginBottom: 16,
        padding: 32,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: 55,
            display: 'flex',
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
            fontSize: 20,
            lineHeight: 2.4,
            fontWeight: '700',
            letterSpacing: '0.008em',
            marginBottom: 6,
            marginTop: 0,
            [theme.breakpoints.up('md')]: {
                fontSize: 32,
                lineHeight: 1.5,
            }
        },
        '& p': {
            fontSize: 16,
            letterSpacing: '0.032em',
            marginTop: 0,
            lineHeight: 1.5,
            marginBottom: 0,
        },
        '& .image': {
            flex: '0 0 80px',
            maxWidth: 80,
            marginLeft: 12,
        }
    },
    factStackInnerTheme: {
        ...theme.factStackInnerTheme,
    }
})