import React from "react";
import data from "../../../.brew-cache/data.json";
import { navigate } from "gatsby";

export default function GoBack({ fallback }) {
  function back(fallback) {
    if (document.referrer.includes(data.url)) {
      window.history.back();
    } else if (fallback) {
      navigate(`${data.url}${fallback}`);
    } else {
      // if no fallback is provided, remove slug from url
      // get the current url
      const url = window.location.href;
      // split the url into an array
      const urlArray = url.replace(/\/$/, "").split("/");
      // remove the last item from the array
      urlArray.pop();
      // join the array back into a string
      const newUrl = urlArray.join("/");
      // navigate to the new url
      navigate(newUrl);
    }
  }

  return (
    <button className="go-back" onClick={() => back(fallback)}>
      Back
    </button>
  );
}
