export default theme => ({
    brandLinkRow: {
        position: 'relative',
        marginBottom: 36,
        '& .brand-links': {
            width: 'calc(100% + 18px)',
            maxWidth: 'calc(100% + 18px)',
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {   
                width: 'calc(100% + 24px)',
                maxWidth: 'calc(100% + 24px)',
                marginLeft: -12,
            },
            '& .brand-link': {
                flex: '0 0 100%',
                maxWidth: '100%',
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    margin: '0 12px 24px',
                    flex: '0 0 calc(50% - 24px)',
                    maxWidth: 'calc(50% - 24px)',
                },
                [theme.breakpoints.up('lg')]: {
                    margin: '0 12px 24px',
                    flex: '0 0 calc(25% - 24px)',
                    maxWidth: 'calc(25% - 24px)',
                }
            }
        }
    },
    brandLinkRowTheme: {
        ...theme.brandLinkRowTheme,
    }
})