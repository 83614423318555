export default theme => ({
    imageBreakdown: {
        position: 'relative',
        marginBottom: 36,
        padding: '36px 0',
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            content: '""',
            display: 'block',
            top: 0,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50%)',
            bottom: 0,
            zIndex: -1,
            position: 'absolute',
        },
        '& .gatsby-image-wrapper': {
            marginBottom: '0',
            '& > div': {
                paddingBottom: '160% !important',
            }
        },
        '& .left-con': {
            '& .MuiTypography-root': {
                [theme.breakpoints.up('md')]: {
                    textAlign: 'right',
                }
            }
        },
        '& .title-wrap': {
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, &  .MuiTypography-h6': {
                fontStyle: 'normal',
                fontSize: 10,
                textTransform: 'uppercase',
                letterSpacing: '0.153em',
                lineHeight: 1.6,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 20,
                padding: '12px 20px',
                color: theme.palette.secondary.contrastText,
                display: 'table',
                margin: '0 auto 50px',
            },
            '& h1, & h2, & h3, & h4, & h5, & h6' : {
                textAlign: 'left !important',
                [theme.breakpoints.up('md')]: {
                    textAlign: 'center !important',
                }
            }
        }
    },
    imageBreakdownTheme: {
        ...theme.imageBreakdownTheme
    },
})