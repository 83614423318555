import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbFactStackBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  // console.log(blocks);

  return (
    <div
      className={`facts ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.factStack} ${classes.factStackTheme}`}
    >
      {blocks.map((block) => {
        return block;
      })}
    </div>
  );
};

export const CgbFactStackBlockFragment = graphql`
  fragment CgbFactStackBlock on WpBlock {
    ... on WpCgbFactStackBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFactStackBlockPreview = `
    ... on CgbFactStackBlock {
        name
        attributes {
            className
        }
    }
`;
