export default theme => ({
    accordionInner: {
        position: 'relative',
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            marginBottom: 0,
        }
    },
    accordionInnerTheme: {
        ...theme.accordionInnerTheme,
    }
})