import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => styles(theme));

export const CgbLandingBannerBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const [query, setQuery] = useState('');

    let image = null;

    // Use the gatsby image if available
    if(props.attributes.localFile){
        if(props.attributes.localFile.childImageSharp){
            image = <Img
                className="desktop-image"
                fluid={props.attributes.localFile.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                className="desktop-image"
                src={props.attributes.localFile.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper desktop-image">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }

    function handleSubmit(e) {
        if (e) e.preventDefault(e);
        if (!query) return;

        navigate('/product-search', { state: { query } });
    }

    return <div
        className={`landing-banner ${props.attributes.className ? props.attributes.className : ''} ${classes.landingBanner} ${classes.landingBannerTheme}`}
    >
        {image}
        <div className="container">
            <div className="image">
                {blocks[0]}
            </div>
            <div className="content">
                {blocks[1]}
                <form onSubmit={e => handleSubmit(e)}>
                    <label for="search">Search our products</label>
                    <input type="text" name="search" placeholder="Name, Product, Brand, Search" value={query} minLength={2} required onChange={e => setQuery(e.target.value)} />
                    <input type="submit" value="search" />
                </form>
            </div>
        </div>
    </div>
};

CgbLandingBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbLandingBannerBlockFragment = graphql`
    fragment CgbLandingBannerBlock on WpBlock {
        ... on WpCgbLandingBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbLandingBannerBlockAttributes {
                    className
                    mediaUrl
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1660) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbLandingBannerBlockPreview = `
    ... on CgbLandingBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbLandingBannerBlockAttributes {
                className
                mediaUrl
            }
        }
    }
`;