import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbIconListIntroBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`icon-list-intro ${props.attributes.className ? props.attributes.className : ''} ${classes.iconListIntro} ${classes.iconListIntroTheme}`}>
        <Grid container spacing={3} direction="row">
            <Grid item xs={12} md={5} lg={4} >
                {blocks[1]}
                {blocks[2]}
            </Grid>
            <Grid item xs={12} md={7} >
                {blocks[0]}
                {/*<ul>*/}
                    {/*{props.parentAttributes.amenities.map((amenity) => {*/}
                        {/*count = count+1;*/}
                        {/*return <li key={"amenity-"+count}><Img fluid={amenity.iconUriSharp.childImageSharp.fluid} alt="" />{amenity.name}</li>*/}
                    {/*})}*/}
                {/*</ul>*/}
            </Grid>
            <Grid item xs={12} >
                {blocks[3]}
            </Grid>
        </Grid>
    </div>
};

export const CgbIconListIntroBlockFragment = graphql`
  fragment CgbIconListIntroBlock on WpBlock {
    ... on WpCgbIconListIntroBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbIconListIntroBlockPreview = `
    ... on CgbIconListIntroBlock {
        name
        attributes {
            className
        }
    }
`;