export default theme => ({
    goodToGo: {
        position: 'relative',
        marginBottom: 36,
        backgroundColor: theme.palette.secondary.main,
        padding: 24,
        width: 'calc(100vw)',
        marginLeft: '50%',
        transform: 'translateX(-50vw)',
        [theme.breakpoints.up('md')]: {
            padding: '24px 50px',
        },
        '&.alert-bg': {
            backgroundColor: '#A71930',
            '& p': {
                color: 'white',
                '& a': {
                    color: 'white',
                }
            }
        },
        '&::after': {
            content: '""',
            display: 'block',
            height: 1,
            width: '100%',
            backgroundColor: theme.palette.secondary.contrastText,
            marginTop: 20,
            [theme.breakpoints.up('md')]: {
                display: 'inline-block',
                verticalAlign: 'middle',
                marginTop: 0,
                marginLeft: 36,
                width: 'calc(100% - 610px)',
            },
        },
        '& .gatsby-image-wrapper, & figure.wp-block-image': {
            width: 100,
            marginRight: 36,
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        '& p': {
            display: 'inline-block',
            verticalAlign: 'middle',
            width: 'calc(100% - 140px)',
            marginBottom: 0,
            fontSize: 13,
            color: theme.palette.secondary.contrastText,
            [theme.breakpoints.up('md')]: {
                width: 430,
            },
            '& a': {
                color: theme.palette.secondary.contrastText,
                textDecoration: 'underline',
                '&:hover, &:focus': {
                    opacity: '0.9',
                }
            }
        }
    },
    goodToGoTheme: {
        ...theme.goodToGoTheme,
    }
})