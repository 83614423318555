export default theme => ({
    hr: {
        position: 'relative',
        marginBottom: 48,
        border: 'none',
        borderTop: '1px solid',
        borderColor: theme.palette.secondary.main,
    },
    hrTheme: {
        ...theme.hrTheme,
    }
})