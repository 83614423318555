export default (theme) => ({
  listItem: {
    fontSize: 16,
    letterSpacing: "0.032em",
    lineHeight: 1.5,
    "& strong": {
      fontWeight: 700,
      fontSize: 18,
      letterSpacing: "0.008em",
    },
    "& a": {
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: "underline",
      fontSize: 18,
      letterSpacing: "0.008em",
      "&:hover, &:focus": {
        color: theme.palette.primary.dark,
      },
    },
  },
});
