import envelope from "../../../images/envelope.png";

export default theme => ({
    teamGridInner: {
        position: 'relative',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            marginBottom: 80,
        },
        '&:hover': {
            '& .member-image': {
                '&::before': {
                    top: -6,
                    left: -6,
                    right: -6
                },
                '& .hover': {
                    opacity: 1,
                }
            }
        },
        '& .member-image': {
            position: 'relative',
            paddingBottom: 6,
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                top: 'calc(100% - 4px)',
                backgroundColor: theme.palette.primary.main,
                left: 0,
                right: 0,
                zIndex: -1,
                transition: 'all .3s ease-in-out',
            },
            '& .hover': {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                opacity: 0,
                transition: 'opacity .3s ease-in-out',
            }
        },
        '& .con-wrap': {
            paddingTop: 20,
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6,': {
                marginTop: 0,
                marginBottom: 0,
                fontSize: 18,
                letterSpacing: '0.008em'
            },
            '& .job-title': {
                fontSize: 10,
                fontFamily: theme.bodyFont,
                letterSpacing: '0.152em',
                lineHeight: 1.6,
                marginBottom: 10,
                marginTop: 0,
                textTransform: 'uppercase',
            },
            '& p.MuiTypography-body1': {
                fontSize: 14,
                margin: '0 0 12px',
            },
        },
        '& a.email': {
            fontSize: 18,
            fontFamily: theme.bodyFont,
            letterSpacing: '0.008em',
            fontWeight: 700,
            textDecoration: 'none',
            color: theme.palette.primary.main,
            position: 'absolute',
            bottom: 0,
            left: 12,
            [theme.breakpoints.up('lg')]: {
                left: 40,
            },
            '&::before': {
                width: 21,
                height: 16,
                backgroundImage: 'url(' + envelope + ')',
                backgroundPosition: 'center',
                content: '""',
                display: 'inline-block',
                marginRight: 5,
            }
        }
    },
    teamGridInnerTheme: {
        ...theme.teamGridInnerTheme,
    }
})