export default (theme) => ({
  iframe: {
    "& iframe": {
      width: "100%",
      height: "100%",
      minHeight: 1250,
      border: "none",
    },
  },
});
