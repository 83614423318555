import  React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {graphql, useStaticQuery} from "gatsby";
import Link from '../../Link';
import Img from "gatsby-image";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbProductsByTagBlock = (props) => {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query ProductsByTag {
        allWpProductTag {
          nodes {
            id
            name
            slug
            ProductTag {
              tagIntro
              tagImage {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }  
              }
              featuredProducts {
                ... on WpProduct {
                  id
                  uri
                  title
                }
              }
            }
          }
        }
      }
    `);

    return (
      <div className={`products-by-tag ${props.attributes.className ? props.attributes.className : ''} ${classes.prodByTag} ${classes.prodByTagTheme}`}>
        {data.allWpProductTag.nodes.map((tag) => {
          return <div key={tag.id}>
            <h3>{tag.name}</h3>
            <p>{ReactHtmlParser(tag.ProductTag.tagIntro)}</p>
            <div className="links">
              {tag.ProductTag.featuredProducts.map((prod) => {
                return <Link to={prod.uri} key={prod.id}>{prod.title}</Link>
              })}
            </div>
            <Link to={"/tags/"+tag.slug+"/"} className="image-link">
              {tag.ProductTag.tagImage !== null &&
                <Img fluid={tag.ProductTag.tagImage.localFile.childImageSharp.fluid} />
              }
              <span>All {tag.name}</span>
            </Link>
          </div>
        })}
      </div>
    )
};

CgbProductsByTagBlock.propTypes = {
    attributes: PropTypes.object.isRequired
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CgbProductsByTagBlockFragment = graphql`
  fragment CgbProductsByTagBlock on WpBlock {
    ... on WpCgbProductsByTagBlock {
      name
      attributes {
        __typename
        ... on WpCgbProductsByTagBlockAttributes {
            className
        }
      }
    }
  }`;

export const CgbProductsByTagBlockPreview = `
... on CgbProductsByTagBlock {
  name
  attributes {
    __typename
    ... on CgbProductsByTagBlockAttributes {
      className
    }
  }
}
`;