import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbVideoEmbedBlock = (props) => {
    const classes = useStyles();

    // console.log(props);

    return <div
        className={`video-embed ${props.attributes.className ? props.attributes.className : ''} ${classes.videoEmbed} ${classes.videoEmbedTheme}`}
    >
        <div className="video-wrapper">
            <iframe title={"video-banner-"+props.attributes.videoID}
                    src={"https://www.youtube.com/embed/"+props.attributes.videoID+"?autoplay=0&modestbranding=1"}
                    frameBorder="0"
                    allowFullScreen
            />
        </div>
    </div>
};

CgbVideoEmbedBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbVideoEmbedBlockFragment = graphql`
    fragment CgbVideoEmbedBlock on WpBlock {
        ... on WpCgbVideoEmbedBlock {
            name
            attributes {
                __typename
                ... on WpCgbVideoEmbedBlockAttributes {
                    className
                    videoID
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbVideoEmbedBlockPreview = `
    ... on CgbVideoEmbedBlock {
        name
        attributes {
            __typename
            ... on CgbVideoEmbedBlockAttributes {
                className
                videoID
            }
        }
    }
`;