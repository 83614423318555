export default theme => ({
    iconList: {
        margin: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
            columnCount: 2,
        },
    },
    iconListTheme: {
        ...theme.iconListTheme,
    }
})