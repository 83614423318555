import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from "@material-ui/core/Grid/Grid";
import Fade from 'react-reveal/Fade';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTimelineInnerBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    let backgroundImage = null;

    if(props.attributes.localFile != null) {
        // Use the gatsby image if available
        if (props.attributes.localFile) {
            if (props.attributes.localFile.childImageSharp) {
                backgroundImage = <div
                    className="bg-image"
                    style={{backgroundImage: 'url(' + props.attributes.localFile.childImageSharp.fluid.srcWebp + ')'}}
                />
            } else {
                // Gifs do not have a childImageSharp
                backgroundImage = <div
                    className="bg-image"
                    style={{backgroundImage: 'url(' + props.attributes.localFile.publicURL + ')'}}
                />
            }
        }
    } else {
        // This is a preview image
        backgroundImage = <div
            className="bg-image"
            style={{backgroundImage: 'url(' + props.attributes.mediaUrl + ')'}}
        />
    }

    return <div className={`timeline-inner ${props.attributes.className ? props.attributes.className : ''} ${classes.timelineInner} ${classes.timelineInnerTheme}`}>
        {backgroundImage}
        <div className="line" />
        <Grid container spacing={5} direction="row">
            <Grid item xs={12} md={6} >
                <Fade bottom>
                    {blocks[1]}
                </Fade>
            </Grid>
            <Grid item xs={12} md={6} >
                <Fade bottom>
                    {blocks[0]}
                </Fade>
                <Fade bottom>
                    <p className="year">{props.attributes.year}</p>
                </Fade>
                <Fade bottom>
                    {blocks[2]}
                </Fade>
            </Grid>
        </Grid>
    </div>
};

export const CgbTimelineInnerBlockFragment = graphql`
  fragment CgbTimelineInnerBlock on WpBlock {
    ... on WpCgbTimelineInnerBlock {
        name
        attributes {
            className
            year
            mediaUrl
            localFile {
                publicURL
                childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTimelineInnerBlockPreview = `
    ... on CgbTimelineInnerBlock {
        name
        attributes {
            className
            year
            mediaUrl
        }
    }
`;