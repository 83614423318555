import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbAccordionInnerBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  var panelID = Math.random(999);

  return (
    <div
      className={`accordion-inner ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.accordionInner}`}
    >
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${panelID}-content`}
          id={`panel-${panelID}-header`}
        >
          {blocks[0]}
        </AccordionSummary>
        <AccordionDetails>
          {blocks[1]}
          {blocks[2] !== null && blocks[2]}
          {blocks[3] !== null && blocks[3]}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const CgbAccordionInnerBlockFragment = graphql`
  fragment CgbAccordionInnerBlock on WpBlock {
    ... on WpCgbAccordionInnerBlock {
      name
      attributes {
        className
        open
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbAccordionInnerBlockPreview = `
    ... on CgbAccordionInnerBlock {
        name
        attributes {
            className
            open
        }
    }
`;
