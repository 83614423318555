import React from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import { transformLink } from "../../../helpers/";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CoreListItemBlock = (props) => {
  const classes = useStyles();
  return (
    <li
      className={` ${
        props.attributes?.className ? props.attributes.className : ""
      } ${classes.listItem}`}
    >
      {ReactHtmlParser(props.attributes.content, { transform: transformLink })}
    </li>
  );
};

CoreListItemBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

// This is the GraphQL Fragment that will be used within Gatsby queries
export const CoreListItemBlockFragment = graphql`
  fragment CoreListItemBlock on WpBlock {
    ... on WpCoreListItemBlock {
      name
      attributes {
        ... on WpCoreListItemBlockAttributes {
          className
          content
        }
      }
    }
  }
`;

export const CoreListItemBlockPreview = `
  ...on CoreListItemBlock {
    name
    attributes {
      ...on CoreListItemBlockAttributes {
        className
        content
      }
    }
  }
`;
