import timelineDot from "../../../images/timeline-dot.png";

export default (theme) => ({
  timelineInner: {
    position: "relative",
    paddingLeft: 25,
    padding: "50px 0 40px",
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      width: "75%",
    },
    "& .line": {
      width: 5,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.primary.contrastText,
      zIndex: 1,
      [theme.breakpoints.up("md")]: {
        left: "calc(50% - 2.5px)",
      },
    },
    "& .bg-image": {
      position: "absolute",
      top: 75,
      left: "50%",
      bottom: 130,
      zIndex: 1,
      width: "100vw",
      transform: "translateX(-50vw)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      [theme.breakpoints.up("md")]: {
        top: 15,
        bottom: 15,
      },
      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 0,
        right: 0,
        height: 50,
        background: "linear-gradient(to bottom, #101C32 5%,rgba(0,0,0,0) 100%)",
        [theme.breakpoints.up("md")]: {
          height: 150,
        },
      },
      "&::after": {
        position: "absolute",
        content: '""',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        background: "linear-gradient(to top, #101C32 5%,rgba(0,0,0,0) 100%)",
        [theme.breakpoints.up("md")]: {
          height: 150,
        },
      },
    },
    "& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6": {
      color: theme.palette.secondary.contrastText,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: "0.04em",
      fontFamily: theme.headerFont,
      fontWeight: "600",
      position: "relative",
      margin: "0 0 36px",
      padding: "0 8px",
      zIndex: 10,
      [theme.breakpoints.up("md")]: {
        padding: "0 20px",
        fontSize: 24,
        lineHeight: 1.25,
        marginTop: 250,
      },
      "&::before": {
        content: '""',
        backgroundImage: "url(" + timelineDot + ")",
        backgroundRepeat: "no-repeat",
        display: "block",
        position: "absolute",
        top: 0,
        left: -40,
        width: 38,
        height: 45,
        zIndex: 10,
        [theme.breakpoints.up("md")]: {
          left: "100%",
        },
      },
    },
    "& .year": {
      fontSize: 32,
      letterSpacing: "-0.008em",
      fontStyle: "italic",
      lineHeight: 1.25,
      fontWeight: 800,
      color: theme.palette.secondary.contrastText,
      marginTop: -18,
      fontFamily: theme.headerFont,
      padding: "0 12px",
      marginBottom: 12,
      zIndex: 1,
      position: "relative",
      [theme.breakpoints.up("md")]: {
        fontSize: 52,
        lineHeight: 1,
        padding: "0 24px",
        marginTop: -25,
      },
      "&::before": {
        content: '""',
        position: "absolute",
        display: "block",
        left: 0,
        right: 0,
        bottom: 22,
        height: 50,
        background: "linear-gradient(to top, #101C32 5%,rgba(0,0,0,0) 100%)",
        zIndex: -1,
        [theme.breakpoints.up("md")]: {
          bottom: 27,
        },
      },
    },
    "& p, & ul": {
      color: theme.palette.secondary.contrastText,
      padding: "0 12px",
      fontSize: 14,
      letterSpacing: "0.024em",
      lineHeight: 1.75,
      margin: 0,
      zIndex: 3,
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: "0.032em",
        padding: "0 24px",
      },
      "& li": {
        marginBottom: 12,
      },
      "& a": {
        color: theme.palette.secondary.contrastText,
        transition: "color .3s ease-in-out",
        "&:hover, &:focus": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  timelineInnerTheme: {
    ...theme.timelineInnerTheme,
  },
});
