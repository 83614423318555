import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from "@material-ui/core/Grid/Grid";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageLinkRowBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <Grid container spacing={3} direction="row" justify="left" alignItems="center" className={`image-link-row ${props.attributes.className ? props.attributes.className : ''} ${classes.imageLinkRow} ${classes.imageLinkRowTheme}`}>
        {blocks.map((block) => {
            if(blocks.length > 3) {
                return <Grid item xs={12} md={4} lg={3}>
                    {block}
                </Grid>
            } else {
                return <Grid item xs={12} md={4}>
                    {block}
                </Grid>
            }
        })}
    </Grid>
};

export const CgbImageLinkRowBlockFragment = graphql`
  fragment CgbImageLinkRowBlock on WpBlock {
    ... on WpCgbImageLinkRowBlock {
        name
        attributes {
            ... on WpCgbImageLinkRowBlockAttributes {
                className
            }
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkRowBlockPreview = `
    ... on CgbImageLinkRowBlock {
        name
        attributes {
            ... on CgbImageLinkRowBlockAttributes {
                className
            }
        }
    }
`;