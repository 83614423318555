import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbAccordionBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  return (
    <div
      className={`accordion ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.accordion}`}
    >
      {blocks.map((block) => {
        return block;
      })}
    </div>
  );
};

export const CgbAccordionBlockFragment = graphql`
  fragment CgbAccordionBlock on WpBlock {
    ... on WpCgbAccordionBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbAccordionBlockPreview = `
    ... on CgbAccordionBlock {
        name
        attributes {
            className
        }
    }
`;
