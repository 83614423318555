export default theme => ({
    twoCardIntro: {
        position: 'relative',
        marginBottom: 36,
        padding: '70px 0',
        [theme.breakpoints.up('md')]: {
            padding: '150px 0',
           '&::after': {
               content: '""',
               display: 'table',
               clear: 'both',
           }
        },
        '&::before': {
            content: '""',
            display: 'block',
            width: '100vw',
            backgroundColor: theme.palette.background.primary,
            position: 'absolute',
            top: 0,
            left: '50%',
            bottom: 0,
            transform: 'translateX(-50vw)',
        },
        '& *': {
            position: 'relative',
        },
        '& .con-col': {
            marginBottom: 100,
            [theme.breakpoints.up('md')]: {
                float: 'left',
                width: '33.333%',
                marginBottom: 0,
            },
            [theme.breakpoints.up('lg')]: {
                width: '30%',
            },
            '& .tag': {
                textTransform: 'uppercase',
                fontSize: 16,
                letterSpacing: '0.1em',
                lineHeight: '1.5',
                color: theme.palette.primary.main,
                marginBottom: 30,
                fontWeight: '600',
                display: 'block',
            }
        },
        '& .cards': {
            [theme.breakpoints.up('md')]: {
                float: 'right',
                width: '66.666%',
                '& .card': {
                    width: 'calc(50% - 24px)',
                    float: 'right',
                    marginLeft: 24,
                }
            },
        },
        '&.dark-cards': {
            '& .card': {
                backgroundColor: theme.palette.secondary.main,
                '& .con-wrap': {
                    '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                        color: theme.palette.secondary.contrastText,
                    }
                }
            }
        }
    },
    twoCardIntroTheme: {
        ...theme.twoCardIntroTheme,
    }
})