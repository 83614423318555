import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from "../../Link";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbNextPreviousPostsBlock = (props) => {
    const classes = useStyles();

    return <div className={`next-previous ${props.attributes.className ? props.attributes.className : ''} ${classes.nextPrev} ${classes.nextPrevTheme}`}>
        {props.pageContext.prevPath != null &&
            <Link to={props.pageContext.prevPath} className="back">Previous Article</Link>
        }
        {props.pageContext.nextPath != null &&
            <Link to={props.pageContext.nextPath} className="next">Next Article</Link>
        }
    </div>
};

export const CgbNextPreviousPostsBlockFragment = graphql`
  fragment CgbNextPreviousPostsBlock on WpBlock {
    ... on WpCgbNextPreviousPostsBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbNextPreviousPostsBlockPreview = `
    ... on CgbNextPreviousPostsBlock {
        name
        attributes {
            className
        }
    }
`;