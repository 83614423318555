export default theme => ({
    testimonial: {
        position: 'relative',
        maxWidth: 890,
        margin: '0 auto 36px',
        '& .testimonial-quote': {
            fontStyle: 'italic',
            fontSize: 24,
            lineHeight: '1.8',
            letterSpacing: '0.04em',
            margin: '0',
            marginBottom: 30,
            [theme.breakpoints.up('md')]: {
                fontSize: 48,
                lineHeight: 1.5,
            },
            '&::before': {
                content: '\'“\'',
                display: 'inline-block',
                width: 66,
                height: 45,
                verticalAlign: 'text-bottom',
                transformOrigin: '57% 26%',
                textAlign: 'center',
                transform: 'scale(5.5)',
                fontSize: 24,
                [theme.breakpoints.up('md')]: {
                    fontSize: 24,
                    transform: 'scale(6.5)',
                    transformOrigin: '56% 20%',
                },
            }
        },
        '& p': {
            margin: 0,
            marginBottom: 30,
            fontSize: 12,
            lineHeight: 2,
            letterSacing: '0.02em',
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                letterSpacing: '0.032em',
                marginBottom: 50,
            },
        },
        '& .testimonial-name': {
            margin: 0,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: 18,
            lineHeight: 1.5,
            fontFamily: theme.headerFont,
            marginBottom: 16,
            [theme.breakpoints.up('md')]: {
                float: 'left',
                marginBottom: 0,
            },
        },
        '& .testimonial-role': {
            marginBottom: 0,
            letterSpacing: '0.056em',
            fontStyle: 'italic',
            [theme.breakpoints.up('md')]: {
                fontSize: 16,
                textAlign: 'right',
            },
        }
    },
    testimonialTheme: {
        ...theme.testimonialTheme,
    }
})