import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbIconListBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <ul className={`icon-list ${props.attributes.className ? props.attributes.className : ''} ${classes.iconList} ${classes.iconListTheme}`}>
        {blocks.map((block) => {
            return block;
        })}
    </ul>
};

export const CgbIconListBlockFragment = graphql`
  fragment CgbIconListBlock on WpBlock {
    ... on WpCgbIconListBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbIconListBlockPreview = `
    ... on CgbIconListBlock {
        name
        attributes {
            className
        }
    }
`;