import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbFactStackInnerBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  return (
    <div
      className={`fact-block ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.factStackInner} ${classes.factStackInnerTheme}`}
    >
      <div className="content">
        {blocks[1]}
        {blocks[2]}
      </div>
      <div className="image">{blocks[0]}</div>
    </div>
  );
};

export const CgbFactStackInnerBlockFragment = graphql`
  fragment CgbFactStackInnerBlock on WpBlock {
    ... on WpCgbFactStackInnerBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFactStackInnerBlockPreview = `
    ... on CgbFactStackInnerBlock {
        name
        attributes {
            className
        }
    }
`;
