import laptop from '../../../images/laptop-cronex.png';
import laptopResan from '../../../images/laptop-resan.png';

export default theme => ({
    brandLinkGrid: {
        position: 'relative',
        marginBottom: 36,
        paddingTop: 120,
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100vw',
            zIndex: 0,
        },
        '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
            color: theme.palette.primary.contrastText,
        },
        '& p': {
            color: theme.palette.primary.contrastText,
            '& > a': {
                textDecoration: 'underline',
                fontWeight: 500,
                color: theme.palette.primary.contrastText,
                '&:hover, &:focus': {
                    color: theme.palette.primary.contrastText,
                }
            }
        },
        '& button': {
            [theme.breakpoints.up('md')]: {  
                float: 'right',
            }
        },
        '& .brand-links': {
            width: 'calc(100% + 18px)',
            maxWidth: 'calc(100% + 18px)',
            margin: '80px 0 150px 0',
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {   
                width: 'calc(100% + 24px)',
                maxWidth: 'calc(100% + 24px)',
                marginLeft: -12,
            },
            '& .brand-link': {
                flex: '0 0 100%',
                maxWidth: '100%',
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    margin: '0 12px 24px',
                    flex: '0 0 calc(33.333% - 24px)',
                    maxWidth: 'calc(33.333% - 24px)',
                    '&:nth-of-type(4), &:nth-of-type(5)': {
                        flex: '0 0 calc(50% - 24px)',
                        maxWidth: 'calc(50% - 24px)',
                    }
                },
                '&.cronex': {
                    [theme.breakpoints.up('md')]: {
                        paddingRight: 260,
                    },
                    '&::after': {
                        content: '""',
                        width: 140,
                        height: 125,
                        backgroundImage: 'url('+laptop+')',
                        backgroundPosition: 'center right',
                        backgroundRepeat: 'no-repeat',
                        marginLeft: 10,
                        display: 'inline-block',
                        position: 'absolute',
                        right: 0,
                        bottom: 40,
                        backgroundSize: 'contain',
                        [theme.breakpoints.up('md')]: {
                            top: '50%',
                            bottom: 'auto',
                            width: 250,
                            height: 220,
                            transform: 'translateY(-50%)',
                        },
                    },
                    '& .button': {
                        float: 'none',
                        marginBottom: 60,
                        marginTop: 60,
                        width: 160,
                        [theme.breakpoints.up('md')]: {
                            marginBottom: 0,
                            marginTop: 0,
                            width: 200,
                        }
                    }
                },
                '&.resan': {
                    [theme.breakpoints.up('md')]: {
                        paddingRight: 260,
                    },
                    '&::after': {
                        content: '""',
                        width: 140,
                        height: 120,
                        backgroundImage: 'url('+laptopResan+')',
                        backgroundPosition: 'center right',
                        backgroundRepeat: 'no-repeat',
                        marginLeft: 10,
                        display: 'inline-block',
                        position: 'absolute',
                        right: 0,
                        backgroundSize: 'contain',
                        bottom: 40,
                        [theme.breakpoints.up('md')]: {
                            top: '50%',
                            bottom: 'auto',
                            width: 250,
                            height: 220,
                            transform: 'translateY(-50%)',
                        },
                    },
                    '& .button': {
                        float: 'none',
                        marginBottom: 60,
                        width: 160,
                        marginTop: 60,
                        [theme.breakpoints.up('md')]: {
                            marginBottom: 0,
                            marginTop: 0,
                            width: 200,
                        }
                    }
                },
            }
        }
    },
    brandLinkGridTheme: {
        ...theme.brandLinkGridTheme,
    }
})