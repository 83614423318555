
import arrowNext from '../../../images/arrow-next-light.png';
import arrowPrev from '../../../images/arrow-prev-light.png';

export default theme => ({
    logoSlider: {
        // position: 'relative',
        // width: '100vw',
        // marginLeft: '50%',
        // transform: 'translateX(-50vw)',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            marginBottom: 50,
        },
        '& .slick-slider': {
            '& .slick-list': {
                overflow: 'visible',
                '& .slick-track': {
                    '& .slick-slide': {
                        '& > div': {
                            '& .image-slide': {
                                padding: 12,
                                [theme.breakpoints.up('md')]: {
                                    padding: 24,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    padding: 32,
                                },
                                // '& .gatsby-image-wrapper': {
                                //     height: 'auto',
                                //     width: '100vw',
                                //     [theme.breakpoints.up('md')]: {
                                //         width: 'calc(100vw - 160px)',
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
        },
        // '& .slick-prev': {
        //     left: 24,
        //     zIndex: '1',
        //     width: 24,
        //     height: 24,
        //     backgroundSize: 'cover !important',
        //     backgroundColor: 'transparent !important',
        //     backgroundImage: 'url(' + arrowPrev + ') !important',
        //     '&::before': {
        //         display: 'none',
        //     }
        // },
        // '& .slick-next': {
        //     right: 24,
        //     width: 24,
        //     height: 24,
        //     backgroundSize: 'cover !important',
        //     backgroundColor: 'transparent !important',
        //     backgroundImage: 'url(' + arrowNext + ') !important',
        //     [theme.breakpoints.up('md')]: {
        //         right: 184,
        //     },
        //     '&::before': {
        //         display: 'none',
        //     }
        // },
        // '& .slick-dots': {
        //     bottom: 18,
        //     [theme.breakpoints.up('md')]: {
        //         bottom: 50,
        //         left: 0,
        //         width: 'calc(100vw - 160px)',
        //     },
        //     '& li': {
        //         '& button': {
        //             width: 16,
        //             height: 16,
        //             margin: 2,
        //             border: '1px solid',
        //             borderColor: theme.palette.secondary.main,
        //             backgroundColor: theme.palette.secondary.contrastText,
        //             borderRadius: '50%',
        //             opacity: '0.25',
        //             transition: 'opacity 250ms ease-in-out',
        //             [theme.breakpoints.up('md')]: {
        //                 backgroundColor: theme.palette.secondary.main,
        //             },
        //             '&::before': {
        //                 display: 'none',
        //             },
        //             '&:hover, &:focus': {
        //                opacity: '0.5',
        //             }
        //         },
        //         '&.slick-active': {
        //             '& button' : {
        //                 opacity: 1,
        //             }
        //         }
        //     }
        // }
    },
})