import tdLogo from "../../../images/td-logo.png";
import liquid from "../../../images/pre-video-liquid.png";

import specBg from "../../../images/specifiers-bg.png";
import blueGrad from "../../../images/blue-gradient.png";
import whiteGrad from "../../../images/white-gradient.png";
import bubble from "../../../images/side-bubble.png";

export default theme => ({
    twoColWrapper: {
        position: 'relative',
        marginBottom: 36,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        width: 'calc(100% + 24px)',
        marginLeft: -12,
        marginRight: -12,
        '& .left': {
            flex: '0 0 100%',
            padding: '0 12px',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 43.75%',
                maxWidth: '43.75%',
                marginLeft: '6.25%',
            },
        },
        '& .right': {
            flex: '0 0 100%',
            padding: '0 12px',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 37.5%',
                maxWidth: '37.5%',
                marginLeft: '6.25%',
            },
        },
        '&.pre-video-columns': {
            padding: '72px 0 50px',
            position: 'relative',
            marginBottom: '0 !important',
            [theme.breakpoints.up('md')]: {
                padding: '180px 0',
            },
            '&::before': {
                backgroundColor: theme.palette.primary.main,
                content: '""',
                display: 'block',
                top: 0,
                left: '50%',
                width: '100vw',
                transform: 'translateX(-50%)',
                bottom: 0,
                zIndex: -1,
                position: 'absolute',
            },
            '&::after': {
                backgroundImage: 'url('+liquid+')',
                backgroundPosition: 'center left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                content: '""',
                display: 'block',
                left: '50%',
                width: '100vw',
                height: 50,
                transform: 'translateX(-50%)',
                bottom: -25,
                zIndex: 2,
                position: 'absolute',
                [theme.breakpoints.up('md')]: {
                    height: 200,
                    bottom: -100,
                },
                [theme.breakpoints.up('lg')]: {
                    height: 395,
                    bottom: -200,
                }
            },
            '& .two-col-inner': {
                '& > .MuiTypography-h1, & > .MuiTypography-h2, & > .MuiTypography-h3, & > .MuiTypography-h4, & > .MuiTypography-h5, & > .MuiTypography-h6, & > .MuiTypography-body1': {
                    color: theme.palette.primary.contrastText,
                },
                '& .made-in-britain': {
                    [theme.breakpoints.up('md')]: {
                        paddingLeft: 160,
                        fontSize: 48,
                        fontWeight: 700,
                        lineHeight: 1.33
                    }
                }
            }
        },
        '&.landing-columns': {
            '& .left': {  
                '& p': {
                    fontSize: 18,
                    letterSpacing: 0,
                    lineHeight: 1.55,
                    fontWeight: 500,
                    [theme.breakpoints.up('md')]: {
                        fontSize: 24,
                        lineHeight: 1.66,
                    }
                }
            },
            [theme.breakpoints.up('lg')]: {
                '& .left': {
                    flex: '0 0 37.5%',
                    maxWidth: '37.5%',
                    marginLeft: '6.25%',
                },
                '& .right': {
                    flex: '0 0 25%',
                    maxWidth: '25%',
                    marginLeft: '25%',
                }
            },
            '&::after': {
                content: '""',
                display: 'block',
                flex: '0 0 100%',
                maxWidth: '100%',
                height: 19,
                backgroundImage: 'url('+tdLogo+')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'top left',
                [theme.breakpoints.up('md')]: {
                    margin: '120px 0',
                    height: 21,
                    backgroundPosition: 'center top',
                }
            }
        },
        '&.specifiers-intro': {
            position: 'relative',
            paddingTop: 100,
            paddingBottom: 100,
            marginTop: -36,
            '&:before': {
                content: '""',
                position: 'absolute',
                width: '100vw',
                left: '50%',
                zIndex: 0,
                top: 0,
                bottom: 0,
                transform: 'translateX(-50%)',
                background: 'url('+blueGrad+') top center repeat-x, url('+whiteGrad+') bottom center repeat-x, url('+specBg+') center no-repeat',
                backgroundSize: 'auto, auto, cover'
            },
            '& .two-col-inner > h1, & .two-col-inner > h2, & .two-col-inner > h3, & .two-col-inner > h4, & .two-col-inner > h5, & .two-col-inner > h6, & .two-col-inner > p': {
                color: 'white',
            },
            '& .facts:before': {
                display: 'none !important',
            },
            [theme.breakpoints.up('md')]: {
                '& .left': {
                    flex: '0 0 37.5%',
                    maxWidth: '37.5%',
                    marginLeft: '6.25%',
                },
                '& .right': {
                    flex: '0 0 43.75%',
                    maxWidth: '43.75%',
                    marginLeft: '6.25%',
                }
            }
        },
        '&.bubble-bg': {
            position: 'relative',
            paddingTop: 180,
            paddingBottom: 180,
            marginTop: 100,
            '&:before': {
                content: '""',
                position: 'absolute',
                width: '100vw',
                left: '50%',
                zIndex: 0,
                top: 0,
                bottom: 0,
                backgroundColor: theme.palette.primary.main,
                transform: 'translateX(-50%)',
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                maxWidth: 425,
                top: -155,
                zIndex: 0,
                height: 315,
                right: '50%',
                transform: 'translateX(50vw)',
                backgroundSize: 'contain',
                backgroundPosition: 'center right',
                backgroundImage: 'url('+bubble+')',
            },
            '& .two-col-inner > h1, & .two-col-inner > h2, & .two-col-inner > h3, & .two-col-inner > h4, & .two-col-inner > h5, & .two-col-inner > h6, & .two-col-inner > p': {
                color: 'white',
            },
            '& .facts:before': {
                display: 'none !important',
            },
            [theme.breakpoints.up('md')]: {
                '& .left': {
                    flex: '0 0 37.5%',
                    maxWidth: '37.5%',
                    marginLeft: '6.25%',
                },
                '& .right': {
                    flex: '0 0 43.75%',
                    maxWidth: '43.75%',
                    marginLeft: '6.25%',
                }
            }
        }
    },
    twoColWrapperTheme: {
        // ...theme.twoColWrapperTheme,
    }
})