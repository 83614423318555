import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const AcfWaveSpacerBlock = (props) => {
  const classes = useStyles();

  return (
    <div
      className={`wave-spacer ${
        props.attributes.className ? props.attributes.className : ""
      } top-${props.block.blockWaveSpacer.topColour} bottom-${
        props.block.blockWaveSpacer.bottomColour
      } ${classes.waveSpacer}`}
    >
      <svg
        preserveAspectRatio="none"
        className="wave-space"
        x="0px"
        y="0px"
        viewBox="0 0 1803.8 170.4"
        style={{ enableBackground: "new 0 0 1803.8 170.4" }}
      >
        <path
          className="bottom"
          d="M1803.8,54.9v115.4H0v-54.2c0,0,394.5-228,909.2-30.9C1423.9,282.4,1803.8,54.9,1803.8,54.9z"
        />
        <path
          className="top"
          d="M1803.8,0.4v54.6c0,0-379.9,227.5-894.6,30.4C394.5-111.8,0,116.2,0,116.2V0.4H1803.8z"
        />
      </svg>
    </div>
  );
};

AcfWaveSpacerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const AcfWaveSpacerBlockFragment = graphql`
  fragment AcfWaveSpacerBlock on WpBlock {
    ... on WpAcfWaveSpacerBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpAcfWaveSpacerBlockAttributes {
          className
        }
      }
      blockWaveSpacer {
        topColour
        bottomColour
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const AcfWaveSpacerBlockPreview = `
    ... on AcfWaveSpacerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on AcfWaveSpacerBlockAttributes {
                className
            }
        }
        blockWaveSpacer {
          topColour
          bottomColour
        }
    }
`;
