
import rotateDevice from '../../../images/rotate-device.png';
import expandView from '../../../images/expand-arrows-alt.png';

export default theme => ({
    galleryGridInnerLarge: {
        position: 'relative',
        '& .image-item': {
            display: 'inline-block',
            width: '50%',
            padding: '0 8px',
            position: 'relative',
            cursor: 'pointer',
            '&::before': {
                position: 'absolute',
                top: 12,
                right: 20,
                content: '""',
                width: 25,
                height: 25,
                backgroundImage: 'url(' + expandView + ')',
                backgroundSize: 'cover',
                zIndex: '1',
            },
            [theme.breakpoints.up('md')]: {
                width: '25%',
                float: 'right',
            },
            '& .gatsby-image-wrapper': {
                marginBottom: 16,
                '& > div': {
                    paddingBottom: '100% !important',
                }
            },
            '&:nth-of-type(1)': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '50%',
                    float: 'left',
                }
            }
        },
        '&.flipped': {
            '& .image-item': {
                [theme.breakpoints.up('md')]: {
                    float: 'left',
                },
                '&:nth-of-type(1)': {
                    [theme.breakpoints.up('md')]: {
                        float: 'right',
                    }
                }
            },
        },
        '& .full-screen-image': {
            position: 'fixed',
            zIndex: 999999,
            backgroundColor: theme.palette.secondary.main,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'none',
            backgroundImage: 'url(' + rotateDevice + ')',
            backgroundPosition: 'center bottom 5px',
            backgroundSize: '215px 78px',
            backgroundRepeat: 'no-repeat',
            '&[data-active="true"]': {
                display: 'block',
            },
            '& .close-image': {
                color: theme.palette.secondary.contrastText,
                fontSize: 16,
                letterSpacing: '0.016em',
                lineHeight: 1.5,
                margin: '0 0 30px auto',
                display: 'block',
                verticalAlign: 'middle',
                position: 'absolute',
                top: 15,
                right: 15,
                zIndex: '1',
                '& .MuiButton-label': {
                    '& span': {
                        backgroundColor: theme.palette.secondary.contrastText,
                        width: 18,
                        height: 18,
                        clipPath: 'polygon(7px 1px, 8px 0px, 10px 0px, 11px 1px, 11px 7px, 17px 7px, 18px 8px, 18px 10px, 17px 11px, 11px 11px, 11px 17px, 10px 18px, 8px 18px, 7px 17px, 7px 11px, 1px 11px, 0px 10px, 0px 8px, 1px 7px, 7px 7px)',
                        display: 'inline-block',
                        verticalAlign: 'text-top',
                        transform: 'rotate(45deg)',
                    }
                },
                [theme.breakpoints.up('md')]: {
                    float: 'right',
                }
            },
            '& .gatsby-image-wrapper': {
                '& > div': {
                    paddingBottom: '100vh !important',
                },
                '& img': {
                    objectFit: 'contain !important',
                }
            },
        },
        '&::after': {
            clear: 'both',
            display: 'table',
            content: '""',
        }
    },
    galleryGridInnerLargeTheme: {
        ...theme.galleryGridInnerLargeTheme,
    }
})