import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import SocialShare from "../../SocialShare";
import styles from './styles';
import GoBack from '../../GoBack';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBlogBannerBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    // console.log(props.pageContext);

    return <div
        className={`blog-banner ${props.attributes.className ? props.attributes.className : ''} ${classes.blogBanner} ${classes.blogBannerTheme}`}
    >
        <div className="banner">
            <div className="date">{props.pageContext.date}</div>
            {blocks.map((block) => {
                return block;
            })}
        </div>
        <div className="extras">
            <div className="go-back">
                <GoBack />
            </div>
            <div className="share">
                <SocialShare
                    path={props.pageContext.pagePath}
                    title={props.pageContext.pageTitle}
                />
            </div>
        </div>
    </div>
};

CgbBlogBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbBlogBannerBlockFragment = graphql`
    fragment CgbBlogBannerBlock on WpBlock {
        ... on WpCgbBlogBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbBlogBannerBlockAttributes {
                    className
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBlogBannerBlockPreview = `
    ... on CgbBlogBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbBlogBannerBlockAttributes {
                className
            }
        }
    }
`;