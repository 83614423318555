export default theme => ({
    featuredIcons: {
        position: 'relative',
        marginBottom: 36,
        padding: '85px 0',
        '&::before': {
            backgroundColor: theme.palette.secondary.main,
            content: '""',
            position: 'absolute',
            top: 0,
            left: '50%',
            width: '100vw',
            transform: 'translateX(-50%)',
            bottom: 0,
            display: 'block',
        },
        '& h2': {
            color: theme.palette.secondary.contrastText,
            position: 'relative',
        }
    },
    featuredIconsTheme: {
        ...theme.featuredIconsTheme,
    }
})