export default theme => ({
    iconRowInner: {
        display: 'block',
        backgroundColor: theme.palette.secondary.main,
        padding: 32,
        textAlign: 'left',
        borderBottom: '1px solid #707070',
        [theme.breakpoints.up('md')]: {
            padding: '68px 32px',
            textAlign: 'center',
            borderBottom: 'none',
            borderLeft: '1px solid #707070',
            flex: '0 0 33.3333%',
            maxWidth: '33.333%',
        },
        '& .gatsby-image-wrapper': {
            fontSize: 0,
            display: 'inline-block',
            marginRight: 24,
            verticalAlign: 'middle',
        },
        '& span': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontWeight: 500,
            color: theme.palette.secondary.contrastText,
            fontSize: 16,
            letterSpacing: '0.016em',
            lineHeight: 1.5,
            paddingRight: 5,
            [theme.breakpoints.up('md')]: {
                fontSize: 24,
                letterSpacing: '0',
            },
        }
    },
    iconRowInnerTheme: {
        ...theme.iconListInnerTheme,
    }
})