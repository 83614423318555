import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbSeparatorBlock = (props) => {
    const classes = useStyles();

    return <hr className={`${props.attributes.className ? props.attributes.className : ''} ${classes.hr} ${classes.hrTheme}`} />
};

export const CgbSeparatorBlockFragment = graphql`
  fragment CgbSeparatorBlock on WpBlock {
    ... on WpCgbSeparatorBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbSeparatorBlockPreview = `
    ... on CgbSeparatorBlock {
        name
        attributes {
            className
        }
    }
`;