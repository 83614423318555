import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbVideoBannerMessageBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div
        className={`banner-message ${props.attributes.className ? props.attributes.className : ''} ${classes.bannerMessage} ${classes.bannerMessageTheme}`}
    >
        <div className="gatsby-image-wrapper">
            <div style={{paddingBottom: '50%'}} />
            <div className="video-wrapper">
                <iframe title={"video-banner-"+props.attributes.videoID}
                        src={"https://player.vimeo.com/video/"+props.attributes.videoID+"?allowfullscreen=1&amp;webkitallowfullscreen=1&autoplay=1&muted=1&background=1"}
                        frameBorder="0"
                        allowFullScreen
                />
            </div>
        </div>
        <div className="content-wrap">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

CgbVideoBannerMessageBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbVideoBannerMessageBlockFragment = graphql`
    fragment CgbVideoBannerMessageBlock on WpBlock {
        ... on WpCgbVideoBannerMessageBlock {
            name
            attributes {
                __typename
                ... on WpCgbVideoBannerMessageBlockAttributes {
                    className
                    videoID
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbVideoBannerMessageBlockPreview = `
    ... on CgbVideoBannerMessageBlock {
        name
        attributes {
            __typename
            ... on CgbVideoBannerMessageBlockAttributes {
                className
                videoID
            }
        }
    }
`;